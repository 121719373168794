<template>
  <div>
    <input v-model="keywords" type="text">

    <ul>
      <li v-for="item in result" :key="item.id">
        <h5>{{ item.title }}</h5>
        <p>{{ item.answer }}</p>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const keywords = ref('')

const data = [
    {
        "id": 0,
        "title": "现阶段我国社会主要矛盾是人民日益增长的美好生活需要和不平衡不充分的发展之间的矛盾。",
        "answer": "A"
    },
    {
        "id": 1,
        "title": "人民民主是社会主义的根基，是全面建设社会主义现代化国家的应有之义。",
        "answer": "B"
    },
    {
        "id": 2,
        "title": "中国共产党是为中国人民谋幸福、为中华民族谋复兴的党，也是为人类谋进步、为世界谋大同的党。",
        "answer": "A"
    },
    {
        "id": 3,
        "title": "中国式现代化是全体人民共同富裕的现代化。",
        "answer": "A"
    },
    {
        "id": 4,
        "title": "共同富裕是中国特色社会主义的根本特征，也是一个长期的历史过程。",
        "answer": "B"
    },
    {
        "id": 5,
        "title": "我们经过接续奋斗，实现了现代化这个中华民族的千年梦想。",
        "answer": "B"
    },
    {
        "id": 6,
        "title": "我国建成世界第二大的高速铁路网、高速公路网，机场港口、水利、能源、信息等基础设施建设取得重大成就。",
        "answer": "B"
    },
    {
        "id": 7,
        "title": "实践性是马克思主义的本质属性，党的理论是来自人民、为了人民、造福人民的理论，人民的创造性实践是理论创新的不竭源泉。",
        "answer": "B"
    },
    {
        "id": 8,
        "title": "我们建成世界上规模第二大的教育体系、社会保障体系、医疗卫生体系，教育普及水平实现历史性跨越。",
        "answer": "B"
    },
    {
        "id": 9,
        "title": "我们坚持精准扶贫、尽锐出战，打赢了中国历史上规模最大的脱贫攻坚战。",
        "answer": "B"
    },
    {
        "id": 10,
        "title": "全国八百三十二个贫困县全部摘帽，近一亿农村贫困人口实现脱贫，九百六十多万贫困人口实现易地搬迁，历史性地解决了绝对贫困问题，为全球减贫事业作出了重大贡献。",
        "answer": "A"
    },
    {
        "id": 11,
        "title": "我们全面推进中国特色大国外交，推动构建人类命运共同体。",
        "answer": "A"
    },
    {
        "id": 12,
        "title": "我们坚持以马克思主义为指导，是要运用其科学的世界观和方法论解决中国的问题，而不是要背诵和重复其具体结论和词句。",
        "answer": "A"
    },
    {
        "id": 13,
        "title": "中国人民在长期生产生活中积累的宇宙观、天下观、社会观、道德观，同科学社会主义价值观主张具有高度契合性。",
        "answer": "A"
    },
    {
        "id": 14,
        "title": "物质富足、精神富有是社会主义现代化的根本要求。",
        "answer": "A"
    },
    {
        "id": 15,
        "title": "坚持走中国人权发展道路，积极参与全球人权治理，推动人权事业全面发展。",
        "answer": "A"
    },
    {
        "id": 16,
        "title": "全面建设社会主义现代化国家，是一项伟大而艰巨的事业，前途光明，任重道远。",
        "answer": "A"
    },
    {
        "id": 17,
        "title": "巩固优势产业领先地位，在关系安全发展的领域加快补齐短板，提升战略性资源供应保障能力。",
        "answer": "A"
    },
    {
        "id": 18,
        "title": "坚定不移走生产发展、生活富裕、生态良好的文明发展道路。",
        "answer": "A"
    },
    {
        "id": 19,
        "title": "党的二十大报告指出，推进以发展为核心的新型城镇化，加快农业转移人口市民化。",
        "answer": "B"
    },
    {
        "id": 20,
        "title": "依托我国超大规模市场优势，以国际大循环吸引全球资源要素，增强国内国际两个市场两种资源联动效应，提升贸易投资合作质量和水平。",
        "answer": "B"
    },
    {
        "id": 21,
        "title": "坚持农业农村优先发展，坚持城乡联动发展，畅通城乡要素流动。",
        "answer": "B"
    },
    {
        "id": 22,
        "title": "实施产业基础再造工程和重大技术装备攻关工程，支持专精特新企业发展，推动制造业数字化、智能化、绿色化发展。",
        "answer": "A"
    },
    {
        "id": 23,
        "title": "加强人大代表工作能力建设，密切人大代表同人民群众的联系。健全吸纳民意、汇集民智工作机制，建设好基层立法联系点。",
        "answer": "A"
    },
    {
        "id": 24,
        "title": "分配制度是促进共同富裕的根本性制度。",
        "answer": "B"
    },
    {
        "id": 25,
        "title": "教育是最重要的民生。",
        "answer": "B"
    },
    {
        "id": 26,
        "title": "发展壮大医疗卫生队伍，把工作重点放在城乡和社区。",
        "answer": "B"
    },
    {
        "id": 27,
        "title": "国家安全是民族复兴的前提。",
        "answer": "B"
    },
    {
        "id": 28,
        "title": "全面建设社会主义现代化国家，必须有一支业务过硬、适应新时代要求、具备领导现代化建设能力的干部队伍。",
        "answer": "B"
    },
    {
        "id": 29,
        "title": "我国全面依法治国总体格局已全面形成。",
        "answer": "B"
    },
    {
        "id": 30,
        "title": "我们深入贯彻以人民为中心的发展思想，在幼有所育、学有所教、劳有所得、病有所医、老有所养、住有所居、弱有所扶上持续用力，人民生活全方位改善。\n\n",
        "answer": "A"
    },
    {
        "id": 31,
        "title": "我国制造业规模、外汇储备稳居世界第二。",
        "answer": "B"
    },
    {
        "id": 32,
        "title": "统筹推进政党协商、人大协商、政府协商、政协协商、人民团体协商、基层协商以及社会组织协商，健全各种制度化协商平台，推进协商民主广泛多层制度化发展。",
        "answer": "A"
    },
    {
        "id": 33,
        "title": "加强重点领域、新兴领域、涉外领域立法，统筹推进国内法治和涉外法治，以良法促进法治、实现善治。",
        "answer": "B"
    },
    {
        "id": 34,
        "title": "统筹立改废释纂，增强立法系统性、整体性、协同性、时效性。",
        "answer": "A"
    },
    {
        "id": 35,
        "title": "江山就是人民，人民就是江山。中国共产党领导人民打江山、守江山，守的是国家主权和领土完整。",
        "answer": "B"
    },
    {
        "id": 36,
        "title": "全面推进中华民族伟大复兴，关键在国家安全。",
        "answer": "B"
    },
    {
        "id": 37,
        "title": "中国共产党领导的多党合作和政治协商制度是我国一项基本国策。",
        "answer": "B"
    },
    {
        "id": 38,
        "title": "政治建设是党永葆生机活力、走好新的赶考之路的必由之路。",
        "answer": "B"
    },
    {
        "id": 39,
        "title": "对外开放是中国人民创造历史伟业的必由之路。",
        "answer": "B"
    },
    {
        "id": 40,
        "title": "我国十四亿多人口整体迈进现代化社会，规模超过现有发达国家人口的总和，艰巨性和复杂性前所未有，发展途径和推进方式也必然具有自己的特点。",
        "answer": "A"
    },
    {
        "id": 41,
        "title": "我们要以科学的态度对待科学、以真理的精神追求真理。",
        "answer": "A"
    },
    {
        "id": 42,
        "title": "我国人均预期寿命增长到七十八点二岁。",
        "answer": "A"
    },
    {
        "id": 43,
        "title": "十年来，我们取得一系列标志性成果，经受住了来自政治、经济、意识形态、自然界等方面的风险挑战考验。",
        "answer": "A"
    },
    {
        "id": 44,
        "title": "保障进城落户农民土地所有权，鼓励依法自愿有偿转让。",
        "answer": "B"
    },
    {
        "id": 45,
        "title": "我们完善外交总体布局，积极建设覆盖全球的伙伴关系网络，推动构建新型国际关系。",
        "answer": "A"
    },
    {
        "id": 46,
        "title": "我们开展了史无前例的反腐败斗争，以“得罪千百人、不负十四亿”的使命担当祛疴治乱，“打虎”、“拍蝇”、“猎狐”多管齐下，反腐败斗争取得彻底性胜利并全面巩固。",
        "answer": "B"
    },
    {
        "id": 47,
        "title": "问题是时代的声音，回答并指导解决问题是理论的根本任务。",
        "answer": "A"
    },
    {
        "id": 48,
        "title": "改革开放和社会主义现代化建设深入推进，书写了经济快速发展和社会长期稳定两大奇迹新篇章。",
        "answer": "A"
    },
    {
        "id": 49,
        "title": "拥有马克思主义科学理论指导是我们党坚定信仰信念、把握历史主动的根本所在。",
        "answer": "A"
    },
    {
        "id": 50,
        "title": "习近平新时代中国特色社会主义思想是中国特色社会主义理论体系的重要组成部分。",
        "answer": "A"
    },
    {
        "id": 51,
        "title": "坚持中国共产党领导是最高政治原则",
        "answer": "B"
    },
    {
        "id": 52,
        "title": "只有植根本国、本民族历史文化沃土，马克思主义真理之树才能根深叶茂。",
        "answer": "A"
    },
    {
        "id": 53,
        "title": "不断谱写马克思主义中国化时代化新篇章，是当代中国共产党人的庄严历史责任。",
        "answer": "A"
    },
    {
        "id": 54,
        "title": "我们坚持把实现人民对美好生活的向往作为现代化建设的出发点和落脚点，着力维护和促进社会公有制，着力促进全体人民共同富裕，坚决防止两极分化。",
        "answer": "B"
    },
    {
        "id": 55,
        "title": "我们坚持可持续发展，坚持节约优先、保护优先、人为恢复为主的方针，像保护眼睛一样保护自然和生态环境。",
        "answer": "B"
    },
    {
        "id": 56,
        "title": "我们坚定站在历史正确的一边、站在人类文明进步的一边，高举和平、发展、合作、共赢旗帜。",
        "answer": "A"
    },
    {
        "id": 57,
        "title": "习近平新时代中国特色社会主义思想是全党全国人民为实现中华民族伟大复兴而奋斗的行动指南，必须长期坚持并不断发展。",
        "answer": "A"
    },
    {
        "id": 58,
        "title": "规范司法权力运行，健全公安机关、检察机关、审判机关、司法行政机关各司其职、相互配合、相互制约的体制机制。",
        "answer": "A"
    },
    {
        "id": 59,
        "title": "我们比历史上任何时期都更接近、更有信心和能力实现中华民族伟大复兴的目标，同时必须准备付出更为艰巨、更为艰苦的努力。",
        "answer": "A"
    },
    {
        "id": 60,
        "title": "发展是党执政兴国的第一要务。",
        "answer": "A"
    },
    {
        "id": 61,
        "title": "全面建设社会主义现代化国家，最艰巨最繁重的任务仍然在基层。",
        "answer": "B"
    },
    {
        "id": 62,
        "title": "教育是国之大计、党之大计。",
        "answer": "A"
    },
    {
        "id": 63,
        "title": "中国式现代化是走和平发展道路的现代化。我国不走一些国家通过战争、殖民、掠夺等方式实现现代化的老路。",
        "answer": "A"
    },
    {
        "id": 64,
        "title": "坚持和完善“一国两制”制度体系，落实中央管治权。",
        "answer": "B"
    },
    {
        "id": 65,
        "title": "培养造就大批德才兼备的高素质人才，是国家和民族长远发展大计。",
        "answer": "A"
    },
    {
        "id": 66,
        "title": "完善产权保护、市场准入、公平竞争、社会信用等市场经济基础制度，优化营商环境。",
        "answer": "A"
    },
    {
        "id": 67,
        "title": "严肃稳妥处置不合格党员，保持党员队伍先进性和政治性。",
        "answer": "B"
    },
    {
        "id": 68,
        "title": "深化整治权力集中、资金密集、资源富集领域的腐败，坚决惩治群众身边的“蝇贪”。",
        "answer": "A"
    },
    {
        "id": 69,
        "title": "牢牢掌握党对意识形态工作领导权，全面落实意识形态工作责任制，巩固壮大奋进新时代的主流思想舆论。",
        "answer": "A"
    },
    {
        "id": 70,
        "title": "如期实现建军一百年奋斗目标，加快把人民军队建成世界一流军队，是全面建设社会主义现代化国家的战略要求。",
        "answer": "A"
    },
    {
        "id": 71,
        "title": "法治政府是构筑法治国家的基础。",
        "answer": "B"
    },
    {
        "id": 72,
        "title": "意识形态工作是为国家立心、为民族立魂的工作。",
        "answer": "A"
    },
    {
        "id": 73,
        "title": "社会主义核心价值观是凝聚人心、汇聚民力的强大力量。",
        "answer": "A"
    },
    {
        "id": 74,
        "title": "只要存在腐败问题产生的土壤和条件，反腐败斗争就一刻不能停，必须永远吹冲锋号。",
        "answer": "A"
    },
    {
        "id": 75,
        "title": "发挥政治巡视利剑作用，加强巡视整改和成果运用。",
        "answer": "A"
    },
    {
        "id": 76,
        "title": "加强军事力量常态化多样化运用，坚定灵活开展军事斗争，塑造安全态势，遏控危机冲突，打赢周边战争。",
        "answer": "B"
    },
    {
        "id": 77,
        "title": "人民健康是民族昌盛和国家强盛的重要标志。",
        "answer": "A"
    },
    {
        "id": 78,
        "title": "坚定维护国际公平正义，倡导践行真正的多边主义，旗帜鲜明反对一切霸权主义和强权政治，毫不动摇反对任何单边主义、保护主义、霸凌行径。",
        "answer": "A"
    },
    {
        "id": 79,
        "title": "牢固树立和践行绿水青山就是金山银山的理念，站在人与自然和谐共生的高度谋划发展。",
        "answer": "A"
    },
    {
        "id": 80,
        "title": "推动经济社会发展科技化、低碳化是实现高质量发展的关键环节。",
        "answer": "B"
    },
    {
        "id": 81,
        "title": "坚定维护国家政权安全、制度安全、意识形态安全，加强重点领域安全能力建设，确保粮食、能源资源、重要产业链供应链安全。",
        "answer": "A"
    },
    {
        "id": 82,
        "title": "坚持中国特色社会主义道路，既不走封闭僵化的老路，也不走改旗易帜的邪路。",
        "answer": "A"
    },
    {
        "id": 83,
        "title": "全方位夯实粮食安全根基，全面落实粮食安全党政同责。",
        "answer": "A"
    },
    {
        "id": 84,
        "title": "要始终赢得人民拥护、巩固长期执政地位，必须时刻保持解决大党独有难题的清醒和坚定。",
        "answer": "A"
    },
    {
        "id": 85,
        "title": "十年来，城镇化率提高十一点六个百分点，达到百分之六十四点七。",
        "answer": "A"
    },
    {
        "id": 86,
        "title": "新时代的伟大成就是党和人民一道拼出来、干出来、奋斗出来的",
        "answer": "A"
    },
    {
        "id": 87,
        "title": "十年来，我国经济总量占世界经济的比重达百分之十八点五，提高七点二个百分点，稳居世界第二位。",
        "answer": "A"
    },
    {
        "id": 88,
        "title": "把握作风建设地区性、行业性、阶段性特点，抓住普遍发生、反复出现的问题深化整治，推进作风建设常态化长效化。",
        "answer": "A"
    },
    {
        "id": 89,
        "title": "我们要着力解决好人民群众急难愁盼问题，健全基本社会服务体系，提高公共服务水平，增强均衡性和可及性，扎实推进共同富裕。",
        "answer": "B"
    },
    {
        "id": 90,
        "title": "要强化就业优先政策，健全就业促进机制，促进高质量充分就业。",
        "answer": "A"
    },
    {
        "id": 91,
        "title": "社会主义核心价值观是凝聚人心、汇聚民力的强大力量。",
        "answer": "A"
    },
    {
        "id": 92,
        "title": "就业是最重要的民生。",
        "answer": "B"
    },
    {
        "id": 93,
        "title": "发挥党和国家功勋荣誉表彰的精神引领、典型示范作用，推动全社会见贤思齐、崇尚英雄、争做先锋。",
        "answer": "A"
    },
    {
        "id": 94,
        "title": "广泛开展全民健身活动，加强青少年体育工作，促进群众体育和竞技体育全面发展，加快建设体育强国。",
        "answer": "A"
    },
    {
        "id": 95,
        "title": "十九大、十九届六中全会提出的“十个明确”、“十四个坚持”、“十六个方面成就”概括了新时代中国特色社会主义思想的主要内容，必须长期坚持并不断丰富发展。",
        "answer": "B"
    },
    {
        "id": 96,
        "title": "加快实施一批具有战略性全局性前瞻性的国家重大科技项目，增强自主创新能力。",
        "answer": "A"
    },
    {
        "id": 97,
        "title": "建立生态产品价值实现机制，完善生态保护补偿制度。",
        "answer": "A"
    },
    {
        "id": 98,
        "title": "完善国家安全力量布局，构建全域联动、立体高效的国家安全防护体系。",
        "answer": "A"
    },
    {
        "id": 99,
        "title": "我们加快推进科技自立自强，全社会研发经费支出从一万亿元增加到二万八千亿元，居世界第二位，研发人员总量居世界首位。",
        "answer": "A"
    },
    {
        "id": 100,
        "title": "党的二十大报告指出，必须坚持守正创新。我们从事的是前无古人的伟大事业，守正才能不迷失方向、不犯颠覆性错误，创新才能把握时代、引领时代。",
        "answer": "A"
    },
    {
        "id": 101,
        "title": "强化社会治安整体防控，推进扫黑除恶常态化，依法严惩群众反映强烈的严重违法犯罪活动。",
        "answer": "B"
    },
    {
        "id": 102,
        "title": "没有坚实的物质技术基础，就不可能全面建成社会主义现代化强国。",
        "answer": "A"
    },
    {
        "id": 103,
        "title": "走过百年奋斗历程的中国共产党在革命性锻造中更加坚强有力。",
        "answer": "A"
    },
    {
        "id": 104,
        "title": "统筹乡村基础设施和公共服务布局，建设宜居宜业和美乡村。",
        "answer": "A"
    },
    {
        "id": 105,
        "title": "人与自然是生命共同体，无止境地向自然索取甚至破坏自然必然会遭到大自然的报复。",
        "answer": "A"
    },
    {
        "id": 106,
        "title": "坚持以人民为中心发展教育，加快建设高质量教育体系，发展义务教育，促进教育公平。",
        "answer": "B"
    },
    {
        "id": 107,
        "title": "世界各国弘扬和平、发展、公平、正义、民主、自由的全人类共同价值，共同应对各种全球性挑战。",
        "answer": "A"
    },
    {
        "id": 108,
        "title": "坚持学思用贯通、知信行统一，把新时代中国特色社会主义思想转化为坚定理想、锤炼党性和指导实践、推动工作的强大力量。",
        "answer": "A"
    },
    {
        "id": 109,
        "title": "发展海洋经济，保护海洋生态环境，加快建设海洋强国。",
        "answer": "A"
    },
    {
        "id": 110,
        "title": "建设更高水平的平安中国，以新安全格局保障新发展格局。",
        "answer": "A"
    },
    {
        "id": 111,
        "title": "贯彻新发展理念是新时代我国发展壮大的必由之路。",
        "answer": "A"
    },
    {
        "id": 112,
        "title": "党的领导是全面的、系统的、整体的，必须全面、系统、整体加以落实。",
        "answer": "A"
    },
    {
        "id": 113,
        "title": "协商民主是实践全过程人民民主的重要形式。",
        "answer": "A"
    },
    {
        "id": 114,
        "title": "基层民主是全过程人民民主的重要体现。",
        "answer": "A"
    },
    {
        "id": 115,
        "title": "坚持依法治国首先要坚持依宪治国，坚持依法执政首先要坚持依宪执政。",
        "answer": "A"
    },
    {
        "id": 116,
        "title": "健全城镇社区治理体系，及时把矛盾纠纷化解在基层、化解在萌芽状态。",
        "answer": "B"
    },
    {
        "id": 117,
        "title": "坚持科学决策、民主决策、依法决策，全面落实重大决策程序制度。",
        "answer": "A"
    },
    {
        "id": 118,
        "title": "统筹水资源、水环境、水生态治理，推动重要江河湖库生态保护治理，消除城市黑臭水体。",
        "answer": "B"
    },
    {
        "id": 119,
        "title": "实施国家文化数字化战略，健全现代公共文化服务体系，创新实施文化惠民工程。",
        "answer": "A"
    },
    {
        "id": 120,
        "title": "推行草原森林河流湖泊湿地休养生息，实施好长江五年禁渔，健全耕地休耕轮作制度。",
        "answer": "B"
    },
    {
        "id": 121,
        "title": "完善按要素分配政策制度，探索多种渠道增加中低收入群众要素收入，多渠道增加城乡居民财产性收入。",
        "answer": "A"
    },
    {
        "id": 122,
        "title": "树立大食物观，发展设施农业，构建多元化食物供给体系。",
        "answer": "A"
    },
    {
        "id": 123,
        "title": "优化民营企业发展环境，依法保护民营企业产权和企业家权益，促进民营经济发展壮大。",
        "answer": "A"
    },
    {
        "id": 124,
        "title": "完善干部考核评价体系，引导干部树立和践行正确政绩观，推动干部能上能下、能进能出，形成能者上、优者奖、庸者下、劣者汰的良好局面。",
        "answer": "A"
    },
    {
        "id": 125,
        "title": "抓好后继有人这个根本大计，健全培养选拔优秀年轻干部常态化工作机制，把到基层和艰苦地区锻炼成长作为年轻干部培养的重要途径。",
        "answer": "A"
    },
    {
        "id": 126,
        "title": "良好的群众基础是党的优势所在、力量所在。",
        "answer": "B"
    },
    {
        "id": 127,
        "title": "党风问题关系执政党的生死存亡。",
        "answer": "A"
    },
    {
        "id": 128,
        "title": "建设覆盖全民的现代公共法律服务体系，深入开展法治宣传教育，增强全民法治观念。",
        "answer": "B"
    },
    {
        "id": 129,
        "title": "腐败是危害党的生命力和战斗力的最大毒瘤，反腐败是最彻底的自我革命。",
        "answer": "A"
    },
    {
        "id": 130,
        "title": "加强新时代廉洁文化建设，教育引导广大党员、干部增强不想腐的自觉，清清白白做人、干干净净做事。",
        "answer": "A"
    },
    {
        "id": 131,
        "title": "提高防范化解重大风险能力，严密防范系统性安全风险，严厉打击敌对势力渗透、破坏、颠覆、分裂活动。",
        "answer": "A"
    },
    {
        "id": 132,
        "title": "中国积极参与全球治理体系改革和建设，践行共商共建共享的全球治理观",
        "answer": "A"
    },
    {
        "id": 133,
        "title": "以零容忍态度反腐惩恶，更加有力遏制增量，更加有效清除存量。",
        "answer": "A"
    },
    {
        "id": 134,
        "title": "我国发展进入战略机遇和风险挑战并存、不确定难预料因素增多的时期，各种“黑天鹅”、“灰犀牛”事件随时可能发生。",
        "answer": "A"
    },
    {
        "id": 135,
        "title": "党用伟大奋斗创造了百年伟业，也一定能用新的伟大奋斗创造新的伟业。",
        "answer": "A"
    },
    {
        "id": 136,
        "title": "坚持以高质量为中心的发展思想，不断实现发展为了人民、发展依靠人民、发展成果由人民共享。",
        "answer": "B"
    },
    {
        "id": 137,
        "title": "实践没有止境，理论创新也没有止境。",
        "answer": "A"
    },
    {
        "id": 138,
        "title": "全面建设社会主义现代化国家，必须充分发挥亿万人民的创造伟力。",
        "answer": "A"
    },
    {
        "id": 139,
        "title": "坚决查处政治问题和经济问题交织的腐败，坚决防止领导干部成为利益集团和权势团体的代言人、代理人。",
        "answer": "A"
    },
    {
        "id": 140,
        "title": "健全共建共治共享的社会治理制度，提升社会治理效能。",
        "answer": "A"
    },
    {
        "id": 141,
        "title": "完善特别行政区司法制度和法律体系，保持香港、澳门资本主义制度和生活方式长期不变。",
        "answer": "A"
    },
    {
        "id": 142,
        "title": "弘扬党的光荣传统和优良作风，促进党员干部特别是领导干部带头深入调查研究，扑下身子干实事、谋实招、求实效。",
        "answer": "A"
    },
    {
        "id": 143,
        "title": "科学社会主义在二十一世纪的中国焕发出新的蓬勃生机。",
        "answer": "A"
    },
    {
        "id": 144,
        "title": "探索实行排污许可制，健全现代环境治理体系。",
        "answer": "B"
    },
    {
        "id": 145,
        "title": "马克思主义中国化为人类实现现代化提供了新的选择。",
        "answer": "B"
    },
    {
        "id": 146,
        "title": "促进大国协调和良性互动，推动构建和平共处、总体稳定、均衡发展的大国关系格局。",
        "answer": "A"
    },
    {
        "id": 147,
        "title": "党除了工人阶级和最广大人民群众的利益，没有自己特殊的利益。",
        "answer": "A"
    },
    {
        "id": 148,
        "title": "锲而不舍落实中央八项规定精神，持续深化纠治“四风”，重点纠治形式主义、官僚主义。",
        "answer": "A"
    },
    {
        "id": 149,
        "title": "标志着中国新民主主义革命开端的历史事件是五四运动。",
        "answer": "A"
    },
    {
        "id": 150,
        "title": "1921年7月23日至8月初，中国共产党第一次全国代表大会先后在上海、嘉兴召开。",
        "answer": "A"
    },
    {
        "id": 151,
        "title": "第一次明确提出彻底的反帝反封建民主革命纲领的会议是中共二大。",
        "answer": "A"
    },
    {
        "id": 152,
        "title": "第一次国共合作正式建立的标志是国民党第一次全国代表大会的召开。",
        "answer": "A"
    },
    {
        "id": 153,
        "title": "全面抗战爆发后，八路军打的第一个大胜仗是平型关大捷。",
        "answer": "A"
    },
    {
        "id": 154,
        "title": "1940年8月至12月，八路军在华北发动的百团大战，给日军以沉重打击。",
        "answer": "A"
    },
    {
        "id": 155,
        "title": "1942年5月，日军对太行抗日根据地发动大“扫荡”，左权指挥部队掩护八路军总部突围转移时，不幸牺牲。",
        "answer": "A"
    },
    {
        "id": 156,
        "title": "刘某形迹可疑，公安派出所民警对其进行盘问、检查、发现其包里携带大量手机，遂将其带到公安派出所继续盘问。对其继续盘问时间为带至公安派出所之时起不超过24小时，在特殊情况下，经县级以上公安机关批准，可以延长至48小时。",
        "answer": "A"
    },
    {
        "id": 157,
        "title": "某县公安局治安大队办理一起赌博行政案件，因案情非常复杂，经过严格的批准手续，可以采取技术侦察措施。",
        "answer": "B"
    },
    {
        "id": 158,
        "title": "某县拆迁户围堵县政府，并欲冲进政府大楼，县公安局根据情况可以决定实行现场管制。",
        "answer": "B"
    },
    {
        "id": 159,
        "title": "实行现场管制中，对滞留现场人员，公安机关的人民警察可以使用高压水枪、催泪弹等驱逐性警械进行强行驱散。",
        "answer": "A"
    },
    {
        "id": 160,
        "title": "公安派出所民警在下班途中看见有人正在抢劫，认为自己已下班，遂打110民警报警后回家",
        "answer": "B"
    },
    {
        "id": 161,
        "title": "公安派出所民警在巡逻时发现一名女子昏倒在街上，应当立即救助。",
        "answer": "A"
    },
    {
        "id": 162,
        "title": "公民李某因协助民警陈某制服犯罪分子而受伤，应当给予抚恤或补偿。",
        "answer": "A"
    },
    {
        "id": 163,
        "title": "甲县公安局发现其所属乙公安派出所作出的行政处罚有错误，应当撤销该公安派出所的行政处罚决定。",
        "answer": "A"
    },
    {
        "id": 164,
        "title": "甲县公安局民警刘某刑讯逼供，情节严重，经甲县公安局局长批准，可以对其采取停止执行职务的措施。",
        "answer": "A"
    },
    {
        "id": 165,
        "title": "醉酒驾驶机动车，以暴力、威胁方法阻碍公安机关依法检查，又构成妨害公务罪等其他犯罪的，依照处罚较重的规定定罪处罚。",
        "answer": "B"
    },
    {
        "id": 166,
        "title": "王某对刘某实施抢劫，刘某按照王某要求通过手机银行给其转账6万元。由于在非大额交易时间，只有5万元到账。王某抢劫数额为5万元。",
        "answer": "A"
    },
    {
        "id": 167,
        "title": "陈某于去年1月起利用电信网络技术手段发布虚假欠费短信，每次骗取受害人10元。今年12月，陈某被公安机关抓获，共有400多人被骗。对陈某的诈骗数额应当累计计算，以诈骗罪追究刑事责任。",
        "answer": "A"
    },
    {
        "id": 168,
        "title": "办理电信网络诈骗案件，对涉案银行账户或者涉案第三方支付账户内的款项中权属明确的被害人的合法财产，应当及时返还。",
        "answer": "A"
    },
    {
        "id": 169,
        "title": "钟某在其微信朋友圈发布贩卖毒品信息，共贩卖海洛因30克。钟某的行为构成非法利用信息网络罪。",
        "answer": "B"
    },
    {
        "id": 170,
        "title": "以捏造的事实提起民事诉讼，妨害司法秩序或严重侵害他人合法权益的，构成虚假诉讼罪。",
        "answer": "A"
    },
    {
        "id": 171,
        "title": "容留近亲属吸食、注射毒品，情节轻微的，一律不作为犯罪处理。",
        "answer": "B"
    },
    {
        "id": 172,
        "title": "电子数据事案件发审过程中形成的，以数字化形式存储、处理、传输的，能够证明案件事实的数据，是刑事证据种类之一。",
        "answer": "A"
    },
    {
        "id": 173,
        "title": "甲县公安局民警李某现场处置斗殴案，发现张某正持刀砍人，李某鸣枪警告后，张某停止砍人。由于没有人员伤亡，李某可以不向甲县公安局汇报使用枪支情况。",
        "answer": "B"
    },
    {
        "id": 174,
        "title": "马某在同学聚会后开车回家，遇交警检查发现其醉酒驾驶。马某构成以危险方法危害公共安全罪。",
        "answer": "B"
    },
    {
        "id": 175,
        "title": "某学校校长宋某为节约成本，指示小车驾驶员林某在接送学生上下学时多载学生，造成校车严重超过额定乘员数量运行。宋某的行为构成危险驾驶罪。",
        "answer": "A"
    },
    {
        "id": 176,
        "title": "张某用自己的电脑作为服务器，开设网站专门供他人发布拉客招嫖信息，但其未主动收集拉客招嫖信息并发布。张某的行为构成非法利用信息网罪。",
        "answer": "A"
    },
    {
        "id": 177,
        "title": "张某与陈某系吸食毒品的“瘾君子”，经常通过微信互相交流吸毒经验，张某与陈某构成非法利用信息网络罪。",
        "answer": "B"
    },
    {
        "id": 178,
        "title": "王某系某邪教组织骨干，组织邪教组织成员冲击当地人民政府，扰乱了社会秩序。对王某应当以聚众冲击国家机关罪与利用邪教组织破坏法律实施罪数罪并罚。",
        "answer": "B"
    },
    {
        "id": 179,
        "title": "蒋某与李某共同伪造了某建设公司向李某借6000万元高利贷的借据，并由李某向人民法院提起民事诉讼，造成该建筑公司重大财产损失。蒋某与李某构成虚假诉讼罪。",
        "answer": "A"
    },
    {
        "id": 180,
        "title": "王某为牟取利益，受甲企业委托，违反国家规定修改环境质量监测系统的参数和监测数据。甲企业借机暗中排污，严重污染环境。对王某应当以破坏计算机信息系统罪论处，而不是以污染环境罪的共犯论处。",
        "answer": "A"
    },
    {
        "id": 181,
        "title": "陈某超越许可证规定的矿区范围采矿，应当认定为“未取得采矿许可证”，对陈某应当以非法采矿罪追究刑事责任。",
        "answer": "B"
    },
    {
        "id": 182,
        "title": "高某向某吸毒人员非法贩卖美沙酮，数量较大，构成贩卖毒品罪。",
        "answer": "A"
    },
    {
        "id": 183,
        "title": "张某在B市C县收到虚假的还款账号短信息，以为是债权人发送的账号，便在B市D县通过网上银行转给犯罪嫌疑人100万元。C县与D县公安机关对该案件均有管辖权。",
        "answer": "A"
    },
    {
        "id": 184,
        "title": "尊重记者的报道权就是尊重公众的知情权，尊重记者的工作也是遵循公安公共管理的职责和规律",
        "answer": "A"
    },
    {
        "id": 185,
        "title": "李某系甲市公安局侦办的某电信网络诈骗犯罪案件的在逃人员，后被乙市公安局抓获。为有利于查清犯罪事实，可以由甲市公安局继续对李某涉嫌的电信网络诈骗犯罪案件进行管辖。",
        "answer": "A"
    },
    {
        "id": 186,
        "title": "某市公安局在侦办电信网络诈骗犯罪案件时，发现涉案电子数据量大，无法提取。针对此情形，该市公安局办案部门负责人可以批准冻结涉案的电子数据。",
        "answer": "B"
    },
    {
        "id": 187,
        "title": "某电信网络诈骗案件终结后，承办该案件的县公安局在将该案移送人民检察院审查起诉时，将封存状态的原始存储介质和收集、提取的电子数据及其备份一并移送。该县公安局做法正确。",
        "answer": "A"
    },
    {
        "id": 188,
        "title": "华某涉嫌组织、领导黑社会性质组织罪被刑事拘留，因其存在可能伪造证据、串供等有碍侦查的情形，办案单位可暂不予通知家属，但有碍侦查的情形消失以后，应当立即通知华某的家属。",
        "answer": "B"
    },
    {
        "id": 189,
        "title": "甲省拟在其境内乙市丙县和丁市戊县交界处建大型化工厂，引发两县大规模社会安全事件。对该事件应当由甲省人民政府负责应对处置。",
        "answer": "A"
    },
    {
        "id": 190,
        "title": "因争夺水利资源，A省B市C区发生大规模械斗。公安机关在参与处置该事件中可强制隔离相互对抗的当事人。",
        "answer": "A"
    },
    {
        "id": 191,
        "title": "甲省乙市丙县公安局实行现场管制，必须报经甲省公安机关和乙市人民政府批准。",
        "answer": "B"
    },
    {
        "id": 192,
        "title": "民警刘某下班回家路上听见有人在呼叫“有人抢劫”，由于已经下班，刘某可以不履行职责。",
        "answer": "B"
    },
    {
        "id": 193,
        "title": "限制人身自由的行政处罚权只能由公安机关、人民检察院和人民法院行使。",
        "answer": "B"
    },
    {
        "id": 194,
        "title": "违法行为人李某在A市实施了殴打他人行为，该行政案件已由A市公安派出所办理，后李某在B市被查获，B市公安机关可以对李某的此行为进行处罚。",
        "answer": "B"
    },
    {
        "id": 195,
        "title": "公安机关询问违法嫌疑人，必须在公安机关进行。情况复杂，可能适用行政拘留处罚的，询问查证的时间不得超过24小时。",
        "answer": "B"
    },
    {
        "id": 196,
        "title": "公安部督察机构承担公安部督察委员会办事机构职能。",
        "answer": "A"
    },
    {
        "id": 197,
        "title": "县级以上公安机关设督察长，由公安机关行政首长兼任。",
        "answer": "A"
    },
    {
        "id": 198,
        "title": "下级公安机关督察机构查处民警违法违纪问题不力的，上级公安机关督察机构应当直接进行督察。",
        "answer": "B"
    },
    {
        "id": 199,
        "title": "由公安部督察机构作出的停止执行职务、禁闭的决定，受理申诉的机关是公安部警务督察局。",
        "answer": "B"
    },
    {
        "id": 200,
        "title": "受理申诉的公安机关认为停止执行职务、禁闭决定确有错误的，应当予以撤销，并在适当范围内为当事人消除影响，恢复名誉。",
        "answer": "A"
    },
    {
        "id": 201,
        "title": "《公安机关督察条例》的立法宗旨包括 “为了完善公安机关监督机制”和“保障公安机关及其人民警察依法履行职责、实施职权和遵守纪律”两个方面。",
        "answer": "A"
    },
    {
        "id": 202,
        "title": "公安部督察委员会领导全国公安机关的督察工作，负责对公安部所属单位和下级公安机关及其人民警察依法履行职责、行使职权和遵守纪律的情况进行监督，对公安部部长负责。",
        "answer": "A"
    },
    {
        "id": 203,
        "title": "现场督察是指警务督察人员对公安机关及其人民警察在执法执勤活动中依法履行职责、行使职权和遵守纪律的情况进行的同步监督和检查。",
        "answer": "A"
    },
    {
        "id": 204,
        "title": "在现场督察中，督察人员可以通过录音、摄影和摄像等手段，获取信息资料或证据。",
        "answer": "A"
    },
    {
        "id": 205,
        "title": "各级公安机关督察机构应当会同有关部门采取座谈、问卷调查等多种形式，每年定期、不定期地组织开展警务评议活动。",
        "answer": "A"
    },
    {
        "id": 206,
        "title": "督察机构对群众投诉的正在发生的公安机关及其人民警察违法违纪行为，核查处置后应将处理结果及时反馈投诉人。",
        "answer": "A"
    },
    {
        "id": 207,
        "title": "公安机关及其人民警察对督察机构作出的决定提出复核，对复核决定仍不服的，可以在收到复核决定书之日起七日内向上一级公安机关督察机构提出申诉，上级督察机构应当在一个月内予以答复。",
        "answer": "A"
    },
    {
        "id": 208,
        "title": "停止执行职务和禁闭，是为制止、查处人民警察严重违法违纪行为，预防事故，对违反纪律的人民警察在必要时采取的处分措施。",
        "answer": "B"
    },
    {
        "id": 209,
        "title": "督察机构可以向本级公安机关所属单位和下级公安机关派出督察人员进行督察。",
        "answer": "A"
    },
    {
        "id": 210,
        "title": "督察机构可以指令下级公安机关督察机构对专门事项进行督察。",
        "answer": "A"
    },
    {
        "id": 211,
        "title": "督察机构不可以向本级公安机关所属单位和下级公安机关派出督察人员进行督察。",
        "answer": "B"
    },
    {
        "id": 212,
        "title": "督察机构对群众投诉的正在发生的公安机关及其人民警察违法违纪行为，核查处置后不必将处理结果及时反馈投诉人。",
        "answer": "B"
    },
    {
        "id": 213,
        "title": "公安机关及其人民警察对督察机构作出的决定提出复核，对复核决定仍不服的，可以在收到复核决定书之日起七日内向上一级公安机关督察机构提出申诉，上级督察机构应当在三个月内予以答复。",
        "answer": "B"
    },
    {
        "id": 214,
        "title": "对不属于公安机关职权范围的信访事项，或者依法应当通过诉讼，仲裁，行政职权范围的复议等法定途径解决的信访事项，不予受理，并告知信访人向有关机关提出或者依照法定程序提出。",
        "answer": "A"
    },
    {
        "id": 215,
        "title": "公安机关对因执法过错导致信访事项发生，造成严重后果的，可以依照有关规定给予通报批评，责令在全警大会做检查或调离执法办案岗位。",
        "answer": "B"
    },
    {
        "id": 216,
        "title": "人民警察依照规定使用武器，来不及警告或者警告后可能导致更为严重危害后果的，可以直接使用武器。",
        "answer": "A"
    },
    {
        "id": 217,
        "title": "人民警察遇有非法举行集会、游行、示威的，经警告无效的，可以使用催泪弹。",
        "answer": "A"
    },
    {
        "id": 218,
        "title": "人民警察的警用标志、制式服装、警械、证件为人民警察专用，其他个人和组织不得持有和使用。",
        "answer": "A"
    },
    {
        "id": 219,
        "title": "对违反纪律的人民警察，可以同时对其采取停止执行职务、禁闭的措施。",
        "answer": "B"
    },
    {
        "id": 220,
        "title": "互联网巡查是发现涉网犯罪线索的一种方式。",
        "answer": "A"
    },
    {
        "id": 221,
        "title": "收集、提取电子数据，应当由两名以上侦查人员进行。",
        "answer": "A"
    },
    {
        "id": 222,
        "title": "跨地域性是网络犯罪的一大特点。",
        "answer": "A"
    },
    {
        "id": 223,
        "title": "将犯罪前科人员及重点人员纳入动态管控，定期研判其上网行为，能提前预警涉网犯罪线索。",
        "answer": "A"
    },
    {
        "id": 224,
        "title": "在新闻媒体首页进行浏览发现，或通过“Ctrl+F”进行关键词检索，在首页显示的内容一般而言热度较高。",
        "answer": "A"
    },
    {
        "id": 225,
        "title": "论坛（贴吧）不需要注册账号就能进行发帖、浏览等操作。",
        "answer": "B"
    },
    {
        "id": 226,
        "title": "网盘具有半公开性特点，网盘中的资源可以选择公开分享也可以不分享。",
        "answer": "A"
    },
    {
        "id": 227,
        "title": "公安网安部门主要承担公共信息网络安全监管、互联网信息监控、网络技术侦察、网络违法犯罪案件侦办、落实网络与信息安全等级保护制度、开展国家网络与信息安全通报预警等六项职责。",
        "answer": "A"
    },
    {
        "id": 228,
        "title": "使用网安部门的技术侦察措施对涉案对象实施查询和侦控的，需要遵循严格的审批程序和手续，并明确嫌疑对象信息或涉案线索。",
        "answer": "A"
    },
    {
        "id": 229,
        "title": "2011年的中国“茉莉花革命”、2014年10月的“香港占中”都是西方反华势力精心策划的、企图颠覆我政权的“颜色革命”。",
        "answer": "A"
    },
    {
        "id": 230,
        "title": "网络色情犯罪主要是指利用互联网，以牟利为目的、制作、复制、贩卖、传播色情信息，或者虽不以牟利为直接目的，传播淫秽信息情节严重的行为，或者引诱、介绍卖淫的违法犯罪行为。",
        "answer": "A"
    },
    {
        "id": 231,
        "title": "“六进六边”工作实施方案中“六进”是指进农村、进车站、进家庭、进企业、进学校、进寺庙。",
        "answer": "B"
    },
    {
        "id": 232,
        "title": "“六进六边”工作实施方案中“六边”是指边采集边管控、边排查边化解、边治理边建设。",
        "answer": "A"
    },
    {
        "id": 233,
        "title": "当单位的安全出口上锁、遮挡，或者占用、堆放物品影响疏散通道畅通时，单位应当责令有关人员当场改正并督促落实。",
        "answer": "A"
    },
    {
        "id": 234,
        "title": "电器产品、燃气用具的安装或者线路、管路的敷设不符合消防安全技术规定的，责令限期改正；逾期不改正的，责令停止使用。",
        "answer": "A"
    },
    {
        "id": 235,
        "title": "公安派出所组织开展安全防范检查是健全群防群治组织、落实安全防范制度、严密防范的基本方法。",
        "answer": "A"
    },
    {
        "id": 236,
        "title": "学生集中进出的校门每岗每班应不少于2名保安人员。",
        "answer": "A"
    },
    {
        "id": 237,
        "title": "学校照明设备应满足紧急疏散要求，应急照明灯在断电后有效照明时长不少于30分钟。",
        "answer": "A"
    },
    {
        "id": 238,
        "title": "学校学生主要进出门的校门有效宽度应不小于3米。",
        "answer": "B"
    },
    {
        "id": 239,
        "title": "“1+3+N”专群联动模式的定义是：社区民警带领辅警、红袖套、综治队员等3支群防骨干，联动N支群防力量，开展群防群治工作。",
        "answer": "B"
    },
    {
        "id": 240,
        "title": "完善社会治理制度体系构建双线融合机制中“双线融合”是指“社区发展治理与社会综合治理双线融合机制”。",
        "answer": "A"
    },
    {
        "id": 241,
        "title": "一次性告知制。群众咨询有关服务事项时，负责接待的窗口工作人员应采取口头或书面形式向群众一次性告知受（办）理服务事项所必须具备的前置条件和必须提供的相关材料、办理程序和办结时限，对群众提供材料不齐全或不符合法定要求的，窗口人员应一次性书面告知其应补充的材料。",
        "answer": "A"
    },
    {
        "id": 242,
        "title": "公民换领二代证工本费每证20元，遗失补领或损坏换领的工本费每证40元。",
        "answer": "A"
    },
    {
        "id": 243,
        "title": "居住在自有房屋，但未取得房屋所有权证的，不能按“合法稳定住所”办理房主本人或配偶的居住证。",
        "answer": "B"
    },
    {
        "id": 244,
        "title": "网络舆论是网民利用互联网对新闻、言论或某个话题发表自己的意见，并经过充分互动所形成的具有一定影响力和某种倾向性的共同意见或集体情绪。",
        "answer": "A"
    },
    {
        "id": 245,
        "title": "实情决定网情，媒体是网络舆论的主体。",
        "answer": "B"
    },
    {
        "id": 246,
        "title": "网络舆情研判，就是对监测得来的网络舆情信息进行认识、分析、研究和甄别，判断舆情信息的价值。",
        "answer": "A"
    },
    {
        "id": 247,
        "title": "从大量的实践看，应对公共突发事件的最好办法还是公开透明，在第一时间发出权威准确的信息，最大限度地压缩谣言传播的空间。面对谣言，更是需要及时发声。",
        "answer": "A"
    },
    {
        "id": 248,
        "title": "在开展舆论引导工作中，讲成就要留有余地，讲问题要辩证客观，不讲官话、假话、空话、套话，防止简单化、片面性等，最根本的要求是不说假话，说好真话。",
        "answer": "A"
    },
    {
        "id": 249,
        "title": "在开展舆论引导和危机处置工作中，真话不全说，假话全不说。",
        "answer": "A"
    },
    {
        "id": 250,
        "title": "在开展舆论引导和危机处置工作中，公信力是核心，影响力是关键，传播力是途径，引导力是目标。公信力、影响力具备，传播力、引导力水到渠成。",
        "answer": "A"
    },
    {
        "id": 251,
        "title": "公安宣传切忌用力过猛，宣传意图过于直白、生硬、做作，宣传效果只会适得其反。",
        "answer": "A"
    },
    {
        "id": 252,
        "title": "危机处理和舆论引导，应第一时间、重讲态度、快讲事实、口径一致、诚讲措施、慎讲结论。",
        "answer": "A"
    },
    {
        "id": 253,
        "title": "在网络舆论生态中，中国式对错定律是：当鸡蛋与石头发生碰撞后，网民往往选择站在鸡蛋那边。",
        "answer": "A"
    },
    {
        "id": 254,
        "title": "国家秘密的保密期限，除另有规定外，绝密级不超过二十年，机密级不超过十年，秘密级不超过五年。",
        "answer": "B"
    },
    {
        "id": 255,
        "title": "脱密期内的涉密人员，未经批准不得出国（境），不得到境外（驻华）机构、组织及外商独资企业工作，不得为境外（驻华）机构、组织、人员及外商独资企业提供劳务、咨询或者其他服务。",
        "answer": "A"
    },
    {
        "id": 256,
        "title": "县级公安机关具有秘密级国家秘密的原始定密权。",
        "answer": "A"
    },
    {
        "id": 257,
        "title": "人事部门应当定期对涉密人员进行复审，原则上核心涉密人员每年复审一次，重要涉密人员每2年复审一次，一般涉密人员每3年复审一次。",
        "answer": "B"
    },
    {
        "id": 258,
        "title": "信息发布保密审查应当坚持“先审查、后公开”和“一事一审”原则。",
        "answer": "A"
    },
    {
        "id": 259,
        "title": "手机不得带入涉密会议室，应存放在手机存放柜内。",
        "answer": "B"
    },
    {
        "id": 260,
        "title": "除特殊情况外，笔记本电脑可用作涉密计算机；安装摄像头的一体机不得用作涉密计算机。",
        "answer": "B"
    },
    {
        "id": 261,
        "title": "将存储、处理国家秘密的涉密计算机、涉密存储设备连接互联网及其他公共信息网络的，建议给予记过处分；情节较重的，建议给予记大过、降级处分；情节严重的，建议给予撤职、开除处分。",
        "answer": "A"
    },
    {
        "id": 262,
        "title": "违反保密法律法规，尚不构成犯罪，涉及秘密级国家秘密5份以上的，属于“情节严重”。",
        "answer": "B"
    },
    {
        "id": 263,
        "title": "涉密人员直系亲属获得境外永久居留权、长期居留许可或者取得外国国籍的，应当及时向机关、单位报告，书面填写《涉密人员重大事项报告表》。",
        "answer": "A"
    },
    {
        "id": 264,
        "title": "同一位民警的公安网计算机可与涉密单机共用显示屏。",
        "answer": "B"
    },
    {
        "id": 265,
        "title": "涉密人员离岗离职前，应当清退所有涉密载体，主要包括涉密纸质载体、涉密电子文件、涉密信息设备，取消其涉密信息系统帐户访问权限和涉密场所出入权限，并将清退的涉密载体登记造册。",
        "answer": "A"
    },
    {
        "id": 266,
        "title": "涉密文件的复印件等同原件管理，必须在涉密复印机上复印，加盖密级专用章和骑缝章。",
        "answer": "A"
    },
    {
        "id": 267,
        "title": "收发涉密纸介质载体，应当履行清点、登记、编号等手续。",
        "answer": "B"
    },
    {
        "id": 268,
        "title": "销毁机密级、秘密级、警务工作秘密纸介质载体前，应当逐件清点、登记；销毁绝密级纸介质载体前，应当逐页清点、登记。",
        "answer": "A"
    },
    {
        "id": 269,
        "title": "同一位民警的涉密单机、公安网计算机、互联网计算机可共用一台打印机。",
        "answer": "B"
    },
    {
        "id": 270,
        "title": "涉密人员在上岗和离岗前应当签订保密承诺书，不签订的，不得上岗或办理离岗手续。",
        "answer": "A"
    },
    {
        "id": 271,
        "title": "《公安工作国家秘密事项范围的规定》中明确公安工作国家秘密事项共有63项，其中绝密级14项，机密级28项，秘密级21项。",
        "answer": "A"
    },
    {
        "id": 272,
        "title": "对逃避、妨碍保密检查的行为，可根据《保密违法违规行为处分建议办法》给予直接负责的主管人员和其他直接责任人员警告、记过处分。",
        "answer": "A"
    },
    {
        "id": 273,
        "title": "党的十八大提出，要把科技创新摆在国家发展全局的核心位置，坚持走中国特色自主创新道路、实施创新驱动发展战略。",
        "answer": "A"
    },
    {
        "id": 274,
        "title": "现在我们同犯罪的较量更多体现在科技手段的掌握运用上。",
        "answer": "A"
    },
    {
        "id": 275,
        "title": "科技信息化在公安工作中具有战略性、基础性、全局性地位。",
        "answer": "A"
    },
    {
        "id": 276,
        "title": "大数据的战略意义在于掌握庞大的数据信息。",
        "answer": "B"
    },
    {
        "id": 277,
        "title": "狭义上讲，云计算就是一种提供资源的网络，使用者可以随时获取“云”上的资源，按需求量使用，并且可以看成是无限扩展的。",
        "answer": "A"
    },
    {
        "id": 278,
        "title": "科技创新是提高社会生产力和综合国力的战略支撑，必须把科技创新摆在国家发展全局的核心位置。",
        "answer": "A"
    },
    {
        "id": 279,
        "title": "没有网络安全就没有国家安全，没有信息化就没有现代化。",
        "answer": "A"
    },
    {
        "id": 280,
        "title": "2016年5月，我国发布《国家创新驱动发展战略纲要》，提出到2020年进入创新型国家行列、到2050年跻身创新型国家前列。",
        "answer": "B"
    },
    {
        "id": 281,
        "title": "公安部大数据平台是全国公安机关互联网政务服务的总枢纽、总支撑、总门户。",
        "answer": "B"
    },
    {
        "id": 282,
        "title": "公安信息资源服务平台是为了满足全国各级公安机关的信息资源获取、共享、交换、应用，以及对外提供服务的综合性平台。",
        "answer": "B"
    },
    {
        "id": 283,
        "title": "公安移动警务是公安业务处理系统的无线延伸，是一种融合无线通信、数据库及计算机网络安全等多种前沿技术的系统。",
        "answer": "A"
    },
    {
        "id": 284,
        "title": "“云搜”是基于省厅信息资源服务平台汇聚的数据资源，通过全文检索技术面向全警提供基本信息查询服务的应用。",
        "answer": "B"
    },
    {
        "id": 285,
        "title": "视频图像信息综合应用平台整合了各类社会视频图像应用系统。",
        "answer": "B"
    },
    {
        "id": 286,
        "title": "350MHz PDT无线数字集群通信的标准拟定单位是中国电子信息产业协会。",
        "answer": "B"
    },
    {
        "id": 287,
        "title": "公安信息网安全管理遵循统一安全策略、统一技术规范、分级管理、属地负责、保障应用的原则。",
        "answer": "A"
    },
    {
        "id": 288,
        "title": "公安信息网用户不得擅自篡改公安信息资源或者审计信息。",
        "answer": "A"
    },
    {
        "id": 289,
        "title": "面对媒体记者，应当有选择性的保留地说，能说的充分说。",
        "answer": "A"
    },
    {
        "id": 290,
        "title": "未经上级部门批准，任何单位和个人不得建立公安信息网与其它网络的联接，不得将公安信息网延伸到公安机关以外单位。",
        "answer": "B"
    },
    {
        "id": 291,
        "title": "安全技术防范是安全防范的三种基本手段之一。",
        "answer": "A"
    },
    {
        "id": 292,
        "title": "视频监控系统被广泛应用在治安防范、打击犯罪、城市管理等领域。",
        "answer": "A"
    },
    {
        "id": 293,
        "title": "有较强的控制能力是心理健康的表现。",
        "answer": "A"
    },
    {
        "id": 294,
        "title": "心理咨询的主要方式是谈话，它就是随意的聊天。",
        "answer": "B"
    },
    {
        "id": 295,
        "title": "情绪会影响人们的身心健康。",
        "answer": "A"
    },
    {
        "id": 296,
        "title": "不良情绪不能预防和调整。",
        "answer": "B"
    },
    {
        "id": 297,
        "title": "不是所有的压力都是有害的。",
        "answer": "A"
    },
    {
        "id": 298,
        "title": "任何挫折事情均具有两面性。",
        "answer": "A"
    },
    {
        "id": 299,
        "title": "部分较轻的心理问题是可以自愈的。",
        "answer": "A"
    },
    {
        "id": 300,
        "title": "出现任何形式和程度的心理问题都意味着患上了心理疾病。",
        "answer": "B"
    },
    {
        "id": 301,
        "title": "心理疾病的诊断和治疗需要到正规医院或找专业人员进行。",
        "answer": "A"
    },
    {
        "id": 302,
        "title": "因个体差异，每个人的睡眠需求不同，所以对自己睡眠时间长短不作绝对化要求，避免出现必须睡够几个小时，睡眠质量必须达到什么标准等睡前焦虑。",
        "answer": "A"
    },
    {
        "id": 303,
        "title": "做操可以放松身心，达到快速入眠的效果，做一些保健操也可以帮助缓解失眠。",
        "answer": "A"
    },
    {
        "id": 304,
        "title": "人际交往能力是指能够妥善处理组织内外或人与人之间关系的能力。",
        "answer": "A"
    },
    {
        "id": 305,
        "title": "在人际交往中懂得尊重理解他人、真诚赞美他人的优点也是促进人际关系的必要方法。",
        "answer": "A"
    },
    {
        "id": 306,
        "title": "积极的心态只有积极的人才可以养成，消极的人不行。",
        "answer": "B"
    },
    {
        "id": 307,
        "title": "任何人都可以通过练习，养成积极的思维和表达习惯，进而拥有积极的心态。",
        "answer": "A"
    },
    {
        "id": 308,
        "title": "对嫌疑车辆进行检查时，为了操作简便，可以直接站在驾驶室窗边。",
        "answer": "B"
    },
    {
        "id": 309,
        "title": "只要被盘查车辆停车了，民警就可以放心在车辆正前方对车辆信息进行拍照核查。",
        "answer": "B"
    },
    {
        "id": 310,
        "title": "对嫌疑车辆的检查，最主要的任务是争取从车辆的各个部位中检查出违禁品。",
        "answer": "B"
    },
    {
        "id": 311,
        "title": "对嫌疑车辆的检查，应首先对司乘人员进行有效控制，才能进一步对人员和车辆进行检查。",
        "answer": "A"
    },
    {
        "id": 312,
        "title": "执法记录仪使用要求，数据原则保存六个月以上。",
        "answer": "A"
    },
    {
        "id": 313,
        "title": "分解结合枪支后可以不用验枪。",
        "answer": "B"
    },
    {
        "id": 314,
        "title": "验枪时，第一步应当打开击锤成待击发状态。",
        "answer": "B"
    },
    {
        "id": 315,
        "title": "交接枪支时应注意枪口方向和食指位置。",
        "answer": "A"
    },
    {
        "id": 316,
        "title": "在日常勤务工作中，应当使所配备武器保持最大弹匣装弹量。",
        "answer": "B"
    },
    {
        "id": 317,
        "title": "QSZ 92式9mm半自动手枪可以使用DAP 9mm钢芯弹、铅芯弹及巴拉贝姆弹。",
        "answer": "A"
    },
    {
        "id": 318,
        "title": "盾棍组合处置刀斧砍杀事件时，如达到开枪条件且具备开枪条件，应果断拔枪射击。",
        "answer": "A"
    },
    {
        "id": 319,
        "title": "只要是刀斧类砍杀事件都应使用盾棍组合进行处置。",
        "answer": "B"
    },
    {
        "id": 320,
        "title": "人民警察制止嫌疑人违法犯罪行为时应当先使用警械，使用警械不能制止的才可以使用武器。",
        "answer": "B"
    },
    {
        "id": 321,
        "title": "长棍腰间戒备式，前手抓握棍身中段略靠后位置，后手抓握棍尾前端一拳处贴于腰际。",
        "answer": "A"
    },
    {
        "id": 322,
        "title": "盾棍组合的目的是合法、安全、有效、快速的使执法相对人失去继续违法犯罪能力。",
        "answer": "A"
    },
    {
        "id": 323,
        "title": "使用直臂带离控制技术时1名警员就够了。",
        "answer": "B"
    },
    {
        "id": 324,
        "title": "跪压控制嫌疑人时，最好直接跪压其脖颈部。",
        "answer": "B"
    },
    {
        "id": 325,
        "title": "民警在做向后倒地保护动作时，可以不用勾头。",
        "answer": "B"
    },
    {
        "id": 326,
        "title": "使用警棍控制嫌疑人，为了更快将其控制住，最好击打其头部或裆部。",
        "answer": "B"
    },
    {
        "id": 327,
        "title": "使用催泪喷剂后如果违法人员服从，警员就等待其自行恢复，不要采取其他行动。",
        "answer": "B"
    },
    {
        "id": 328,
        "title": "中国共产党第一次全国代表大会于1922年7月23日举行，会场设在上海法租界望志路106号（今兴业路76号）。最后一天的会议转移到浙江嘉兴南湖的游船上举行。",
        "answer": "B"
    },
    {
        "id": 329,
        "title": "1922年7月，中国共产党第二次全国代表大会在上海召开，第一次提出明确的反帝反封建的民主革命纲领，为中国人民的革命斗争指明了方向和道路。",
        "answer": "A"
    },
    {
        "id": 330,
        "title": "1923年6月，中国共产党在广州举行第三次全国代表大会，决定共产党员以个人身份加入国民党，实现国共合作，建立革命统一战线。",
        "answer": "A"
    },
    {
        "id": 331,
        "title": "中国共产党第三次全国代表大会通过了党的第一个章程《中国共产党章程》，对党员条件、党的各级组织和党的纪律作了具体规定，并明确阐释了党的民主集中制原则。",
        "answer": "B"
    },
    {
        "id": 332,
        "title": "1923年6月，中国共产党在广州举行第三次全国代表大会，决定共产党员以个人身份加入国民党，实现国共合作，建立革命统一战线。",
        "answer": "A"
    },
    {
        "id": 333,
        "title": "1924年1月，中国国民党在上海举行第一次全国代表大会，大会宣言对三民主义作出新的解释，事实上确立了联俄、联共、扶助农工的三大政策，奠定了国共合作的政治基础，标志着第一次国共合作正式形成。",
        "answer": "B"
    },
    {
        "id": 334,
        "title": "1927年4月27日至5月9日，中国共产党第五次全国代表大会在武汉召开，这是党在中国革命处于危急关头为挽救革命召开的一次重要会议。",
        "answer": "A"
    },
    {
        "id": 335,
        "title": "1929年8月1日，中国共产党领导发动南昌起义，打响了武装反抗国民党的第一枪，标志着中国共产党独立领导革命战争、创建人民军队和武装夺取政权的开始。",
        "answer": "B"
    },
    {
        "id": 336,
        "title": "1927年8月7日，中共中央在汉口召开紧急会议，毛泽东在会上提出“枪杆子里面出政权”的重要思想。这次会议史称“八七会议”。",
        "answer": "A"
    },
    {
        "id": 337,
        "title": "1928年6月18日至7月11日，中国共产党第六次全国代表大会在莫斯科近郊兹维尼果罗德镇“银色别墅”秘密召开。",
        "answer": "A"
    },
    {
        "id": 338,
        "title": "1936年10月，红四、红二方面军先后同红一方面军在甘肃会宁、静宁将台堡会师，标志着中国工农红军长征胜利结束。",
        "answer": "A"
    },
    {
        "id": 339,
        "title": "1949年3月，党的七届三中全会在河北西柏坡召开。会议着重讨论党的工作重心实行战略转移，即从乡村转到城市的问题。",
        "answer": "B"
    },
    {
        "id": 340,
        "title": "我国过渡时期总路线的主体是对农业、手工业和资本主义工商业实行社会主义改造。",
        "answer": "B"
    },
    {
        "id": 341,
        "title": "1954年9月，第一届全国人民代表大会第一次会议在北京举行。会议的召开，标志着人民代表大会制度作为新中国的根本政治制度正式确立。",
        "answer": "A"
    },
    {
        "id": 342,
        "title": "党的八届九中全会正式决定对国民经济实行“调整、巩固、充实、提高”八字方针。",
        "answer": "A"
    },
    {
        "id": 343,
        "title": "党的七届二中全会提出的“两个务必”是：务必使同志们保持谦虚谨慎、不骄不躁的作风，务必使同志们保持艰苦奋斗的作风。",
        "answer": "A"
    },
    {
        "id": 344,
        "title": "1964年12月至1965年1月，四届全国人大一次会议举行。这次会议提出了实现四个现代化的任务。",
        "answer": "B"
    },
    {
        "id": 345,
        "title": "1978年12月召开的党的十一届三中全会，是新中国成立以来党的历史上具有深远意义的伟大转折。这次会议确定把全党的工作重点转移到社会主义现代化建设上来。标志着我国进入改革开放新时期。",
        "answer": "A"
    },
    {
        "id": 346,
        "title": "1981年6月，党的十一届六中全会通过《关于建国以来党的若干历史问题的决议》，标志着党在指导思想上的拨乱反正胜利完成。",
        "answer": "A"
    },
    {
        "id": 347,
        "title": "1982年9月，陈云在党的十二大开幕词中明确提出：“把马克思主义的普遍真理同我国的具体实际结合起来，走自己的道路，建设有中国特色的社会主义”。",
        "answer": "B"
    },
    {
        "id": 348,
        "title": "1987年召开的党的十三大提出并系统论述了社会主义初级阶段理论。",
        "answer": "A"
    },
    {
        "id": 349,
        "title": "党的二十大报告指出，五年来，我们党团结带领人民，（  ），推动党和国家事业取得举世瞩目的重大成就。",
        "answer": "BC"
    },
    {
        "id": 350,
        "title": "十年来，我们经历了对党和人民事业具有重大现实意义和深远历史意义的三件大事：(  ），实现第一个百年奋斗目标。",
        "answer": "ABC"
    },
    {
        "id": 351,
        "title": "全党要把青年工作作为战略性工作来抓，(   )，做青年朋友的知心人、青年工作的热心人、青年群众的引路人。",
        "answer": "AC"
    },
    {
        "id": 352,
        "title": "深入实施（ ），优化重大生产力布局，构建优势互补、高质量发展的区域经济布局和国土空间体系。",
        "answer": "ABCD"
    },
    {
        "id": 353,
        "title": "党的二十大报告指出，要合理缩减外资准入负面清单，依法保护外商投资权益，营造（   ）一流营商环境。",
        "answer": "ABD"
    },
    {
        "id": 354,
        "title": "推进国家安全体系和能力现代化，坚决维护国家安全和社会安全，要（     ）。",
        "answer": "ABCD"
    },
    {
        "id": 355,
        "title": "党和国家深入贯彻以人民为中心的发展思想，建成世界上规模最大的(    )，人民群众获得感、幸福感、安全感更加充实、更有保障、更可持续。",
        "answer": "ABC"
    },
    {
        "id": 356,
        "title": "实施科教兴国战略，强化现代化建设人才支撑，要（    ）。",
        "answer": "ABCD"
    },
    {
        "id": 357,
        "title": "党的二十大报告的主题是：高举中国特色社会主义伟大旗帜，全面贯彻新时代中国特色社会主义思想，弘扬伟大建党精神，自信自强、守正创新，踔厉奋发、勇毅前行，为（     ）而团结奋斗。",
        "answer": "AC"
    },
    {
        "id": 358,
        "title": "全党必须牢记，坚持党的全面领导是坚持和发展中国特色社会主义的必由之路，（    ）",
        "answer": "ABCD"
    },
    {
        "id": 359,
        "title": "深化教育领域综合改革，加强教材建设和管理，完善学校管理和教育评价体系，健全（    ）育人机制。",
        "answer": "ABC"
    },
    {
        "id": 360,
        "title": "我们要坚持对马克思主义的坚定信仰、对中国特色社会主义的坚定信念，坚定（   ），以更加积极的历史担当和创造精神为发展马克思主义作出新的贡献。",
        "answer": "ABCD"
    },
    {
        "id": 361,
        "title": "我们要增强问题意识，聚焦（  )、国际变局中的重大问题、党的建设面临的突出问题，不断提出真正解决问题的新理念新思路新办法。",
        "answer": "ABD"
    },
    {
        "id": 362,
        "title": "加快建设农业强国，扎实推动乡村（   ）、生态、组织振兴。",
        "answer": "ABC"
    },
    {
        "id": 363,
        "title": "中国共产党立志于中华民族千秋伟业，致力于人类和平与发展崇高事业，全党同志务必（     ）。",
        "answer": "ABD"
    },
    {
        "id": 364,
        "title": "实践告诉我们，中国共产党为什么能，中国特色社会主义为什么好，归根到底是（  ）行。",
        "answer": "AD"
    },
    {
        "id": 365,
        "title": "党的二十大报告指出，全党必须牢记，（ ）永远在路上。",
        "answer": "BC"
    },
    {
        "id": 366,
        "title": "中国式现代化的本质要求是：坚持中国共产党领导，坚持中国特色社会主义，实现高质量发展，发展全过程人民民主，丰富人民精神世界，（ ），创造人类文明新形态。",
        "answer": "ABC"
    },
    {
        "id": 367,
        "title": "在全面建设社会主义现代化国家的前进道路上，必须牢牢把握以下重大原则：坚持和加强党的全面领导，（  ）。",
        "answer": "ABCD"
    },
    {
        "id": 368,
        "title": "（  ）是全面建设社会主义现代化国家的基础性、战略性支撑。",
        "answer": "ABC"
    },
    {
        "id": 369,
        "title": "大自然是人类赖以生存发展的基本条件，（  ）是全面建设社会主义现代化国家的内在要求。",
        "answer": "BCD"
    },
    {
        "id": 370,
        "title": "坚持面向（  ）、面向人民生命健康，加快实现高水平科技自立自强。",
        "answer": "ABD"
    },
    {
        "id": 371,
        "title": "经过不懈努力，党找到了自我革命这一跳出治乱兴衰历史周期率的第二个答案，确保党永远（   ）",
        "answer": "ACD"
    },
    {
        "id": 372,
        "title": "广大青年要坚定不移听党话、跟党走，立志做（   ）的新时代好青年。",
        "answer": "ABCD"
    },
    {
        "id": 373,
        "title": "党的二十大报告指出，我们贯彻总体国家安全观，国家安全领导体制和(  ）不断完善，在原则问题上寸步不让，以坚定的意志品质维护国家主权、安全、发展利益，国家安全得到全面加强。",
        "answer": "ABC"
    },
    {
        "id": 374,
        "title": "党的二十大报告指出，万事万物是相互联系、相互依存的。只有用（  ）观点观察事物，才能把握事物发展规律。",
        "answer": "ABC"
    },
    {
        "id": 375,
        "title": "继续推进实践基础上的理论创新，首先要把握好新时代中国特色社会主义思想的世界观和方法论，（   ）贯穿其中的立场观点方法。",
        "answer": "CD"
    },
    {
        "id": 376,
        "title": "我们要健全人民当家作主制度体系，扩大人民有序政治参与，保证人民依法实行（   ）、民主管理、民主监督，发挥人民群众积极性、主动性、创造性。",
        "answer": "BCD"
    },
    {
        "id": 377,
        "title": "我们要坚持以(   )、以军事科技文化社会安全为保障、以促进国际安全为依托，统筹外部安全和内部安全、国土安全和国民安全、传统安全和非传统安全、自身安全和共同安全，统筹维护和塑造国家安全",
        "answer": "ABD"
    },
    {
        "id": 378,
        "title": "要增强文化自信，围绕（  ）、兴文化、展形象建设社会主义文化强国。",
        "answer": "ABD"
    },
    {
        "id": 379,
        "title": "统筹推动（ ），推进城乡精神文明建设融合发展，在全社会弘扬劳动精神、奋斗精神、奉献精神、创造精神、勤俭节约精神，培育时代新风新貌。",
        "answer": "ABD"
    },
    {
        "id": 380,
        "title": "必须坚定不移走中国特色社会主义政治发展道路，坚持（   ），充分体现人民意志、保障人民权益、激发人民创造活力。",
        "answer": "AC"
    },
    {
        "id": 381,
        "title": "弘扬社会主义法治精神，传承中华优秀传统法律文化，引导全体人民做社会主义法治的（  ）。",
        "answer": "ABC"
    },
    {
        "id": 382,
        "title": "坚持（   ），加快建设教育强国、科技强国、人才强国，坚持为党育人、为国育才，全面提高人才自主培养质量，着力造就拔尖创新人才，聚天下英才而用之。",
        "answer": "ACD"
    },
    {
        "id": 383,
        "title": "坚持依法治国、依法执政、依法行政共同推进，坚持法治国家、法治政府、法治社会一体建设，全面推进（   ），全面推进国家各方面工作法治化。",
        "answer": "ABCD"
    },
    {
        "id": 384,
        "title": "党的二十大报告指出，加快发展方式绿色转型。推动经济社会发展（    ）是实现高质量发展的关键环节。",
        "answer": "AD"
    },
    {
        "id": 385,
        "title": "发展壮大群防群治力量，营造见义勇为社会氛围，建设（    ）的社会治理共同体。",
        "answer": "BCD"
    },
    {
        "id": 386,
        "title": "经过十八大以来全面从严治党，我们解决了党内许多突出问题，但党面临的执政考验、（   ）将长期存在，精神懈怠危险、能力不足危险、脱离群众危险、消极腐败危险将长期存在。",
        "answer": "ABC"
    },
    {
        "id": 387,
        "title": "党的二十大报告指出，（ ）加强生态环境保护，生态文明制度体系更加健全，污染防治攻坚向纵深推进，绿色、循环、低碳发展迈出坚实步伐，生态环境保护发生历史性、转折性、全局性变化",
        "answer": "BCD"
    },
    {
        "id": 388,
        "title": "我们要落实新时代党的建设总要求，健全全面从严治党体系，全面推进党的（   ），使我们党坚守初心使命，始终成为中国特色社会主义事业的坚强领导核心。",
        "answer": "ABCD"
    },
    {
        "id": 389,
        "title": "党的二十大报告指出，增强党内政治生活（    ），用好批评和自我批评武器，持续净化党内政治生态。",
        "answer": "ABCD"
    },
    {
        "id": 390,
        "title": "全面加强党的思想建设，坚持用新时代中国特色社会主义思想（ ），组织实施党的创新理论学习教育计划，建设马克思主义学习型政党。",
        "answer": "ABC"
    },
    {
        "id": 391,
        "title": "坚持理论武装同常态化长效化，开展党史学习教育相结合，引导党员、干部不断（ ），传承红色基因，赓续红色血脉。",
        "answer": "ABCD"
    },
    {
        "id": 392,
        "title": "坚持党管干部原则，坚持（ ），把新时代好干部标准落到实处。",
        "answer": "ABCD"
    },
    {
        "id": 393,
        "title": "中国坚持经济全球化正确方向，推动贸易和投资自由化便利化，推进双边、区域和多边合作，促进国际宏观经济政策协调，反对（ ），反对单边制裁、极限施压。",
        "answer": "ACD"
    },
    {
        "id": 394,
        "title": "加强实践锻炼、专业训练，注重在重大斗争中磨砺干部，增强干部（ ）本领。",
        "answer": "ABD"
    },
    {
        "id": 395,
        "title": "中国坚持国家不分大小、强弱、贫富一律平等，尊重各国人民自主选择的发展道路和社会制度，坚决反对一切形式的霸权主义和强权政治，反对（ ）。",
        "answer": "BCD"
    },
    {
        "id": 396,
        "title": "党的二十大报告指出，打造战略威慑力量体系，增加（    ）作战力量比重，加快无人智能作战力量发展，统筹网络信息体系建设运用。",
        "answer": "AB"
    },
    {
        "id": 397,
        "title": "完善正确处理新形势下人民内部矛盾机制，加强和改进人民信访工作，畅通和规范群众诉求表达、利益协调、权益保障通道，完善（   )的基层治理平台。",
        "answer": "ABC"
    },
    {
        "id": 398,
        "title": "党的二十大报告指出，推动能源清洁低碳高效利用，推进（   ）等领域清洁低碳转型。",
        "answer": "BCD"
    },
    {
        "id": 399,
        "title": "坚持（  ）融合发展，加快军事理论现代化、军队组织形态现代化、军事人员现代化、武器装备现代化，提高捍卫国家主权、安全、发展利益战略能力。",
        "answer": "ABC"
    },
    {
        "id": 400,
        "title": "党的二十大报告指出，以（  ）等为重点，加快实施重要生态系统保护和修复重大工程。",
        "answer": "ABD"
    },
    {
        "id": 401,
        "title": "党的二十大报告指出，优化人口发展战略，建立生育支持政策体系，降低（  ）成本。",
        "answer": "ACD"
    },
    {
        "id": 402,
        "title": "统筹产业结构调整、污染治理、生态保护、应对气候变化，协同推进降碳、减污、扩绿、增长，推进（    ）发展。",
        "answer": "BCD"
    },
    {
        "id": 403,
        "title": "坚持按劳分配为主体、多种分配方式并存，构建（  ）协调配套的制度体系。",
        "answer": "BCD"
    },
    {
        "id": 404,
        "title": "党的二十大报告指出，我们要（  ），形成为人民所喜爱、所认同、所拥有的理论，使之成为指导人民认识世界和改造世界的强大思想武器。",
        "answer": "ABCD"
    },
    {
        "id": 405,
        "title": "以国家战略需求需求为导向，集聚力量进行（   ）科技攻关，坚决打赢关键核心技术攻坚战。",
        "answer": "AC"
    },
    {
        "id": 406,
        "title": "优化区域开放布局，巩固东部沿海地区开放先导地位，提高（   ）开放水平。",
        "answer": "CD"
    },
    {
        "id": 407,
        "title": "完善党中央对科技工作统一领导的体制，健全新型举国体制，强化国家战略科技力量，优化配置创新资源，优化（  )定位和布局，形成国家实验室体系。",
        "answer": "ABC"
    },
    {
        "id": 408,
        "title": "党的二十大报告指出，完善人才战略布局，坚持各方面人才一起抓，建设（   ）的人才队伍。",
        "answer": "ACD"
    },
    {
        "id": 409,
        "title": "推进安全生产风险专项整治，加强（ ）安全监管。",
        "answer": "CD"
    },
    {
        "id": 410,
        "title": "加强党的政治建设，严明政治纪律和政治规矩，落实各级党委（党组）主体责任，提高各级党组织和党员干部（   ）。",
        "answer": "ABD"
    },
    {
        "id": 411,
        "title": "加强理想信念教育，引导全党牢记党的宗旨，解决好（  ）这个总开关问题，自觉做共产主义远大理想和中国特色社会主义共同理想的坚定信仰者和忠实实践者。",
        "answer": "ACD"
    },
    {
        "id": 412,
        "title": "党的二十大报告指出，统筹职业教育、高等教育、继续教育协同创新，推进（  ），优化职业教育类型定位。",
        "answer": "BCD"
    },
    {
        "id": 413,
        "title": "我国货物贸易总额居世界第一，吸引外资和对外投资居世界前列，形成（  ）的对外开放格局。",
        "answer": "ABC"
    },
    {
        "id": 414,
        "title": "我们坚持走中国特色社会主义政治发展道路，全面发展全过程人民民主，社会主义民主政治（  ）全面推进。",
        "answer": "BCD"
    },
    {
        "id": 415,
        "title": "我们贯彻总体国家安全观，（  ）得到有效遏制，扫黑除恶专项斗争取得阶段性成果，有力应对一系列重大自然灾害，平安中国建设迈向更高水平。",
        "answer": "ACD"
    },
    {
        "id": 416,
        "title": "我们确立党在新时代的强军目标，贯彻新时代党的强军思想，大刀阔斧深化国防和军队改革，重构（  ）。",
        "answer": "BCD"
    },
    {
        "id": 417,
        "title": "我们全面准确,坚定不移贯彻（    ）方针，坚持依法治港治澳，维护宪法和基本法确定的特别行政区宪制秩序。",
        "answer": "ABCD"
    },
    {
        "id": 418,
        "title": "走过百年奋斗历程的中国共产党在革命性锻造中更加坚强有力，党的（    ）显著增强。",
        "answer": "ABCD"
    },
    {
        "id": 419,
        "title": "我们党勇于进行理论探索和创新，以全新的视野深化对（   ）的认识。",
        "answer": "ACD"
    },
    {
        "id": 420,
        "title": "党的二十大报告指出，扩大社会保险覆盖面，健全基本养老、基本医疗保险筹资和待遇调整机制，推动基本（    ）省级统筹。",
        "answer": "ACD"
    },
    {
        "id": 421,
        "title": "深化行政执法体制改革，全面推进（    ）执法，加大关系群众切身利益的重点领域执法力度，完善行政执法程序，健全行政裁量基准。",
        "answer": "AD"
    },
    {
        "id": 422,
        "title": "改革开放和社会主义现代化建设取得巨大成就，党的建设新的伟大工程取得显著成效，为我们继续前进（     ）。",
        "answer": "ABC"
    },
    {
        "id": 423,
        "title": "党的二十大报告指出，“两个大局”是谋划工作的基本出发点。“两个大局”指的是（  ）。",
        "answer": "AB"
    },
    {
        "id": 424,
        "title": "面对香港局势动荡变化，我们依照（    ）有效实施对特别行政区的全面管治权，落实“爱国者治港”原则，香港局势实现由乱到治的重大转折。",
        "answer": "BD"
    },
    {
        "id": 425,
        "title": "我们创立了新时代中国特色社会主义思想，明确坚持和发展中国特色社会主义的基本方略，提出一系列治国理政（    ），实现了马克思主义中国化时代化新的飞跃。",
        "answer": "ABC"
    },
    {
        "id": 426,
        "title": "党的二十大报告指出，必须更好发挥法治（    ）的保障作用，在法治轨道上全面建设社会主义现代化国家。",
        "answer": "ABC"
    },
    {
        "id": 427,
        "title": "我们确立党在新时代的强军目标，加快国防和军队现代化建设，裁减现役员额三十万胜利完成，人民军队（  ）。",
        "answer": "ABCD"
    },
    {
        "id": 428,
        "title": "新时代十年的伟大变革，在党史、新中国史、（  ）上具有里程碑意义。",
        "answer": "ABC"
    },
    {
        "id": 429,
        "title": "中国式现代化为人类实现现代化提供了新的选择，中国共产党和中国人民为解决人类面临的共同问题提供更多更好的（   ）。",
        "answer": "BCD"
    },
    {
        "id": 430,
        "title": "党的二十大报告指出，推进马克思主义中国化时代化是一个（    ）的过程。",
        "answer": "ACD"
    },
    {
        "id": 431,
        "title": "习近平新时代中国特色社会主义思想是（     ）。",
        "answer": "ABCD"
    },
    {
        "id": 432,
        "title": "党的二十大报告指出,从现在起，中国共产党的中心任务是（   ）。",
        "answer": "ABC"
    },
    {
        "id": 433,
        "title": "到二〇三五年，我国发展的总体目标是：（   ）大幅跃升，人均国内生产总值迈上新的大台阶，达到中等发达国家水平。",
        "answer": "ACD"
    },
    {
        "id": 434,
        "title": "教育的根本问题是（ ）。",
        "answer": "BCD"
    },
    {
        "id": 435,
        "title": "我们要坚持党管人才原则，坚持（ ）。",
        "answer": "ABCD"
    },
    {
        "id": 436,
        "title": "全面依法治国是国家治理的一场深刻革命，关系（   ）。",
        "answer": "ABC"
    },
    {
        "id": 437,
        "title": "党的二十大报告指出，发展面向（）的，民族的科学的大众的社会主义文化，激发全民族文化创新创造活力，增强实现中华民族伟大复兴的精神力量。",
        "answer": "ACD"
    },
    {
        "id": 438,
        "title": "坚持房子是用来住的、不是用来炒的定位，加快建立（ ）的住房制度。",
        "answer": "ABD"
    },
    {
        "id": 439,
        "title": "下列关于坚持总体国家安全观的表述正确的是：（ ）。",
        "answer": "CD"
    },
    {
        "id": 440,
        "title": "中国共产党愿在（ ）原则基础上加强同各国政党和政治组织交流合作，积极推进人大、政协、军队、地方、民间等各方面对外交往。",
        "answer": "ABCD"
    },
    {
        "id": 441,
        "title": "中国坚持在和平共处五项原则基础上同各国发展友好合作，坚持（  ）周边外交方针，深化同周边国家友好互信和利益融合。",
        "answer": "ACD"
    },
    {
        "id": 442,
        "title": "深入推进环境污染防治，坚持（    ），持续深入打好蓝天、碧水、净土保卫战。",
        "answer": "BCD"
    },
    {
        "id": 443,
        "title": "支持和保证人大及其常委会依法行使（   ），健全人大对行政机关、监察机关、审判机关、检察机关监督制度，维护国家法治统一、尊严、权威。",
        "answer": "ABCD"
    },
    {
        "id": 444,
        "title": "加快建设(     )的社会主义司法制度，努力让人民群众在每一个司法案件中感受到公平正义。",
        "answer": "BCD"
    },
    {
        "id": 445,
        "title": "推进城乡精神文明建设融合发展，在全社会弘扬劳动精神、奋斗精神、（   ），培育时代新风新貌。",
        "answer": "ABD"
    },
    {
        "id": 446,
        "title": "健全基层党组织领导的基层群众自治机制，加强基层组织建设，完善基层直接民主制度体系和工作体系，增强城乡社区群众（   ）的实效。",
        "answer": "ABCD"
    },
    {
        "id": 447,
        "title": "党的二十大报告指出，我们要善于通过历史看现实、透过现象看本质，把握好（ ）、宏观和微观、主要矛盾和次要矛盾、特殊和一般的关系，不断提高战略思维、历史思维、辩证思维、系统思维、创新思维、法治思维、底线思维能力。",
        "answer": "AB"
    },
    {
        "id": 448,
        "title": "弘扬以伟大建党精神为源头的中国共产党人精神谱系，深入开展社会主义核心价值观宣传教育，深化（   ）教育，着力培养担当民族复兴大任的时代新人。",
        "answer": "BCD"
    },
    {
        "id": 449,
        "title": "根据《中华人民共和国人民警察法》的规定，人民警察享受国家规定的工资福利待遇包括（）",
        "answer": "ABCD"
    },
    {
        "id": 450,
        "title": "人民警察在办案过程中，遇到（）情况的，应当回避。",
        "answer": "ABCD"
    },
    {
        "id": 451,
        "title": "《中国共产党廉洁自律准则》前四条是党员廉洁自律规范，是对“廉”的具体化，要求党员正确对待和处理好( )之间的关系。",
        "answer": "ABCD"
    },
    {
        "id": 452,
        "title": "《中国共产党廉洁自律准则》是( )",
        "answer": "ABC"
    },
    {
        "id": 453,
        "title": "新《中国共产党廉洁自律准则》试用对象为( )　",
        "answer": "AB"
    },
    {
        "id": 454,
        "title": "中国共产党全体党员和各级党员领导干部必须(  )，必须(  )，必须(  )，必须(  )，努力弘扬中华民族传统美德，廉洁自律，接受监督，永葆党的先进性和纯洁性。",
        "answer": "ACDE"
    },
    {
        "id": 455,
        "title": "组织、利用宗族势力对抗党和政府，妨碍党和国家的方针政策以及决策部署的实施，或者破坏党的基层组织建设的，对( )，给予留党察看或者开除党籍处分。",
        "answer": "ABC"
    },
    {
        "id": 456,
        "title": "通过信息网络、广播、电视、报刊、书籍、讲座、论坛、报告会、座谈会等方式，有(  ) 行为的，情节较轻的，给予警告或者严重警告处分;情节较重的，给予撤销党内职务或者留党察看处分;情节严重的，给予开除党籍处分。",
        "answer": "ABCD"
    },
    {
        "id": 457,
        "title": "《中国共产党廉洁自律准则》和《中国共产党纪律处分条例》体现了(  )",
        "answer": "ABC"
    },
    {
        "id": 458,
        "title": "下列行为属于党员领导干部违反规定干预和插手市场经济活动的是(  )",
        "answer": "ABC"
    },
    {
        "id": 459,
        "title": "有（）行为的，对直接责任者和领导责任者，情节较轻的，给予警告或者严重警告处分;情节较重的，给予撤销党内职务或者留党察看处分;情节严重的，给予开除党籍处分。",
        "answer": "ABC"
    },
    {
        "id": 460,
        "title": "党员干部的(  )不实际工作而获取薪酬或者虽实际工作但领取明显超出同职级标准薪酬，党员干部知情未予纠正的，情节较重的，给予撤销党内职务或者留党察看处分。",
        "answer": "AC"
    },
    {
        "id": 461,
        "title": "《中国共产党纪律处分条例》的任务包括( )",
        "answer": "ABCDE"
    },
    {
        "id": 462,
        "title": "党组织在纪律审查中发现党员有贪污贿赂、失职渎职等刑法规定的行为涉嫌犯罪的，应当给予(  )处分。",
        "answer": "BCD"
    },
    {
        "id": 463,
        "title": "根据《中国共产党纪律处分条例》，党员领导干部有(  )行为的要受到党纪处分。",
        "answer": "ABD"
    },
    {
        "id": 464,
        "title": "党员张某为XX区区长，张某妻子为该区内一家中外合资企业外方委派的高层管理者。依据《中国共产党纪律处分条例》，对这一现象表达正确的是(  )",
        "answer": "BCD"
    },
    {
        "id": 465,
        "title": "《中国共产党纪律处分条例》规定，党员犯罪，有(  ) 情形之一的，应当给予开除党籍处分。",
        "answer": "ABC"
    },
    {
        "id": 466,
        "title": "《中国共产党纪律处分条例》规定，违反会议活动管理规定，有(  )行为之一，对直接责任者和领导责任者，情节较重的，给予警告或者严重警告处分;情节严重的，给予撤销党内职务处分。",
        "answer": "ABCD"
    },
    {
        "id": 467,
        "title": "公安机关内部执法监督包括(  )方面。",
        "answer": "ABCD"
    },
    {
        "id": 468,
        "title": "根据有关规定，人民警察有(  )情形，应当予以辞退。",
        "answer": "ABC"
    },
    {
        "id": 469,
        "title": "根据《中华人民共和国人民警察法》规定，人民警察的政治纪律主要有(  )",
        "answer": "ABC"
    },
    {
        "id": 470,
        "title": "人民警察的任务是(  )",
        "answer": "ABCD"
    },
    {
        "id": 471,
        "title": "《中华人民共和国人民警察使用警械和武器条例》的立法目的是(  )",
        "answer": "ABCD"
    },
    {
        "id": 472,
        "title": "中国共产党的行动指南是（  ）",
        "answer": "ABCDEF"
    },
    {
        "id": 473,
        "title": "中国共产党的纪律包括（  ）",
        "answer": "ABCDEF"
    },
    {
        "id": 474,
        "title": "中国共产党的群众路线是（  ）",
        "answer": "ABCD"
    },
    {
        "id": 475,
        "title": "社会主义核心价值观是指（  ）",
        "answer": "ABC"
    },
    {
        "id": 476,
        "title": "对党员的纪律处分包括（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 477,
        "title": "三重一大决策制度包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 478,
        "title": "“一个带头”、“三个表率” 、“一个模范”是指（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 479,
        "title": "监督执纪“四种形态”包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 480,
        "title": "中央防止干预司法“三个规定”是指（  ）",
        "answer": "ABC"
    },
    {
        "id": 481,
        "title": "徐某因犯罪被剥夺政治权利。根据我国相关法律的规定，剥夺政治权利包括剥夺（ ）",
        "answer": "ABCD"
    },
    {
        "id": 482,
        "title": "某县公安局在查处一起诈骗刑事案件的过程中，要求县移动通信营业部提供某通信用户的电话详单。下列说法正确的有（ ）",
        "answer": "ABC"
    },
    {
        "id": 483,
        "title": "孙某身有残疾、已丧失劳动能力。根据《宪法》的规定，他享有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 484,
        "title": "下列情形中，应当由公安机关执行刑罚的是（ ）",
        "answer": "BD"
    },
    {
        "id": 485,
        "title": "民警驾车追捕逃犯过程中，因车辆发生故障不能继续行驶。这时，一辆出租车路过，民警将其拦下，要求使用该车追捕逃犯，司机以要做生意唯有拒绝，民警强行将车开走，追缉途中致车辆损坏。下列说法正确的是（ ）",
        "answer": "AD"
    },
    {
        "id": 486,
        "title": "在公共交通工具上抢劫，应当以抢劫罪加重处罚。下列属于“公共交通工具”的有（ ）",
        "answer": "BCD"
    },
    {
        "id": 487,
        "title": "下列行为中应当以诈骗共同犯罪论处的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 488,
        "title": "电信网络诈骗犯罪嫌疑人已将诈骗财物用于清偿债务或者转让他人。下列情形中，应当依法追缴的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 489,
        "title": "下列行为构成容留他人吸毒罪的有（ ）",
        "answer": "AD"
    },
    {
        "id": 490,
        "title": "下列行为中构成受贿罪的是（ ）",
        "answer": "AD"
    },
    {
        "id": 491,
        "title": "李某系甲县公安局民警，在乙县逮捕犯罪嫌疑人赵某的过程中，依法开枪击伤赵某。李某开枪后，下列做法正确的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 492,
        "title": "张某（17周岁）某日深夜撬门进入王某经营的手机店拿了柜台里售卖的手机准备离开，由于该手机店用于经营和生活的场所没有明确隔离，王某被张某走路的声音惊醒，便起身查看。张某见被王某发现，遂将王某打倒后逃跑，至王某轻伤。下列说法正确的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 493,
        "title": "郭某在地铁盗窃杨某手机（价值4000余元），熊某在旁掩护。杨某发现手机被偷后，抓住郭某衣服，郭某向杨某挥舞拳头，熊某围上来打了杨某一拳。杨某夺回手机，两人逃走。下列说法正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 494,
        "title": "使用虚假身份证件罪中的“身份证件”包括（ ）",
        "answer": "ABCD"
    },
    {
        "id": 495,
        "title": "下列行为中，情节严重，构成非法利用信息网络罪的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 496,
        "title": "甲系邪教组织教徒，携带2000份宣扬邪教的传单准备到居民小区传播。下列说法正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 497,
        "title": "某邪教组织骨干甲向成员乙宣扬采用自伤方式使自己承受痛苦可以洗脱前世罪孽。乙信以为真，至某大型超市内自焚，导致乙和2名路过群众受重伤。下列说法正确的是（ ）",
        "answer": "AB"
    },
    {
        "id": 498,
        "title": "泄露不应公开的案件信息罪的犯罪主体包括（ ）",
        "answer": "ABCD"
    },
    {
        "id": 499,
        "title": "下列非法采矿行为情节严重的，应当以非法采矿罪追究刑事责任的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 500,
        "title": "下列行为构成容留他人吸毒罪的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 501,
        "title": "赵某恳求国有公司财务主管李某从单位挪用10万元供他炒股，并将一块表（价值人民币3万余元）送给李某。李某做假账10万元交与赵某，赵某表示会尽快归还。20日后，李某用个人财产归还单位10万元。关于本案，下列选项正确的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 502,
        "title": "根据《刑法》的规定，下列罪名中单位可以成为犯罪主体的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 503,
        "title": "甲县公安局领导班子集体研究决定，将公安局罚没账户上的300余万元罚没款用于奖励近年来考核成绩突出的民警。下列说法正确的是（ ）",
        "answer": "BC"
    },
    {
        "id": 504,
        "title": "甲系某市看守所聘用的履行监管职责的非在编监管人员，其认为监区中的犯罪嫌疑人江某很可怜，遂帮助犯罪嫌疑人江某逃跑。下列说法中错误的是（ ）",
        "answer": "BCD"
    },
    {
        "id": 505,
        "title": "下列案件中，被诈骗的财物应当追缴的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 506,
        "title": "甲县人蓝某系怀孕的妇女，在相邻的乙县盗窃5万元后被抓获。蓝某在乙县无固定住所。下列说法正确的是（ ）",
        "answer": "ACD"
    },
    {
        "id": 507,
        "title": "下列关于公安机关的履职行为，说法正确的是（ ）",
        "answer": "BCD"
    },
    {
        "id": 508,
        "title": "根据《突发事件应对法》的规定，下列做法正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 509,
        "title": "下列关于案（事）件处置工作的做法，符合法律规定的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 510,
        "title": "关于全国人民代表大会常务委员会职权表述正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 511,
        "title": "下列选项中，不得使用继续盘问的情形有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 512,
        "title": "依据《治安管理处罚法》的规定，旅馆业的工作人员有下列（ ）的情形，应给予治安管理处罚。",
        "answer": "ACD"
    },
    {
        "id": 513,
        "title": "村民李某在村民委员会殴打王某，并打砸办公桌，扰乱村民委员会的秩序。下列说法正确的是（ ）",
        "answer": "ABD"
    },
    {
        "id": 514,
        "title": "关于当场处罚，下列说法正确的有（ ）",
        "answer": "AD"
    },
    {
        "id": 515,
        "title": "下列属于行政强制措施的有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 516,
        "title": "公安机关办理行政案件，需要在特定地点对违法嫌疑人进行询问。根据《公安机关案例行政案件程序规定》的规定，以下做法正确的有（ ）",
        "answer": "BD"
    },
    {
        "id": 517,
        "title": "下列行为属于立功的有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 518,
        "title": "下列行为中，应当数罪并罚的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 519,
        "title": "关于恐怖活动和极端主义犯罪，下列说法正确的是（ ）",
        "answer": "ABD"
    },
    {
        "id": 520,
        "title": "关于伪造货币犯罪，下列说法正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 521,
        "title": "下列行为构成非法经营罪的是（ ）",
        "answer": "ABC"
    },
    {
        "id": 522,
        "title": "在集资诈骗中，可以认定为以非法占有为目的的情形是（ ）",
        "answer": "ABC"
    },
    {
        "id": 523,
        "title": "下列行为构成刑讯逼供罪的有（ ）",
        "answer": "AB"
    },
    {
        "id": 524,
        "title": "甲以出卖为目的，将乙（女）拐骗至外地后关押于一地下室内，并将乙强奸，后卖给丙。丙收买乙后按照乙的意愿，没有阻碍其返回原籍。关于本案说法正确的是（ ）",
        "answer": "ABD"
    },
    {
        "id": 525,
        "title": "抢夺公私财物达到“数额较大”的50%标准，具有下列（ ）情形的，以抢夺罪处罚。",
        "answer": "ABCD"
    },
    {
        "id": 526,
        "title": "下列情形-一般不适用取保候审的有（ ）",
        "answer": "BD"
    },
    {
        "id": 527,
        "title": "关于刑事案件立案的条件，下列说法正确的是（ ）",
        "answer": "AB"
    },
    {
        "id": 528,
        "title": "关于搜查的说法正确的是（ ）",
        "answer": "BCD"
    },
    {
        "id": 529,
        "title": "田某在宾馆吸食冰毒后产生幻觉，将房间内设施毁坏，价值1000多元。时候田某主动赔偿，并取得宾馆谅解。下列说法正确的是（ ）",
        "answer": "BD"
    },
    {
        "id": 530,
        "title": "下列违反治安管理行为人中，依法应当给予行政拘留处罚但不执行的有（ ）",
        "answer": "AC"
    },
    {
        "id": 531,
        "title": "《廉政准则》规定，党员领导干部不准违反规定在经济 实体、社会团体等单位中（     ） ",
        "answer": "ABC"
    },
    {
        "id": 532,
        "title": "《廉政准则》规定，党员领导干部不准用公款（     ）",
        "answer": "ABC"
    },
    {
        "id": 533,
        "title": "党内监督是指（     ）之间，依据党章和其他重要的党规党法，相互监察、督促的活动。 ",
        "answer": "ABC"
    },
    {
        "id": 534,
        "title": "新形势下反腐败斗争的主要特点是（     ） ",
        "answer": "ABC"
    },
    {
        "id": 535,
        "title": "反腐倡廉教育要面向全社会，把思想道德教育、纪律教育与（     ）和法制教育结合起来。",
        "answer": "ABC"
    },
    {
        "id": 536,
        "title": "制定《公安机关督察条例》的目的是(      )",
        "answer": "ABCD"
    },
    {
        "id": 537,
        "title": "督察制度实施以来被实践反复证明了的主要经验有(      )",
        "answer": "ACD"
    },
    {
        "id": 538,
        "title": "督察部门的主要工作职责有(     ）",
        "answer": "ABCD"
    },
    {
        "id": 539,
        "title": "督察机构应当开展警务评议活动，听取下列(     )单位对公安机关及其人民警察的意见。",
        "answer": "ABCD"
    },
    {
        "id": 540,
        "title": "投诉人的投诉事项属下列(     )情况的，督察机构应当将投诉材料移交有关部门处理。",
        "answer": "ABC"
    },
    {
        "id": 541,
        "title": "督察机构认为公安机关人民警察有下列(     )情形的，督察机构应当提出建议，移送有关部门依法处理。",
        "answer": "BCD"
    },
    {
        "id": 542,
        "title": "督察人员应当具备的条件有(     ）",
        "answer": "AC"
    },
    {
        "id": 543,
        "title": "对于新修订 《公安机关督察条例》发布施行表述正确的有(     )",
        "answer": "BD"
    },
    {
        "id": 544,
        "title": "下列属于现场督察范围事项的有（     ）",
        "answer": "ABCD"
    },
    {
        "id": 545,
        "title": "下列关于公安机关实行派出督察制度的正确说法有（     ）",
        "answer": "ACD"
    },
    {
        "id": 546,
        "title": "公安机关警务督察部门处理的投诉事项包括(     )",
        "answer": "ABD"
    },
    {
        "id": 547,
        "title": "学习宣传贯彻新修订《公安机关督察条例》，要准确理解和全面把握下列(     )几个方面基本内容。",
        "answer": "ABCD"
    },
    {
        "id": 548,
        "title": "大力强化警令执行督察是指，要做到督察工作与公安中心工作、重大警务活动同研究、同部署。督察部门要紧紧围绕总体工作部署，深入基层、深入现场、深入实战，发现查纠（     ）等问题，服务领导决策，维护警令畅通，提升公安机关整体执行力。",
        "answer": "ABCD"
    },
    {
        "id": 549,
        "title": "深入开展执法活动现场督察是指，要把执法督察放在突出位置来抓，针对侦查办案、行政管理中的重点岗位和关键环节，强化（     ），有效预防和及时查纠不作为、乱作为等突出问题，督促举一反三抓整改，健全完善规章制度，提升执法规范化水平。",
        "answer": "ACD"
    },
    {
        "id": 550,
        "title": "树立正确的执法理念，健全完善各项执法制度，加强执法管理，改革执法方式，提高执法水平，坚持（     ）执法，把群众工作同执法活动有机结合，最大限度地追求法律效果和社会效果的统一，提高执法公信力和群众满意度，从执法源头上预防和减少信访问题的发生。",
        "answer": "ABCD"
    },
    {
        "id": 551,
        "title": "《公安机关信访工作规定》着重规范了公安机关办理信访事项的（     ）",
        "answer": "ABCDE"
    },
    {
        "id": 552,
        "title": "下列属于网络水军的黑话有（  ）",
        "answer": "ABCD"
    },
    {
        "id": 553,
        "title": "下列属于黑客攻击破坏的黑话有（  ）",
        "answer": "ABCD"
    },
    {
        "id": 554,
        "title": "采取打印、拍照或者录像等方式固定相关证据的适用情形（  ）",
        "answer": "ABC"
    },
    {
        "id": 555,
        "title": "常用的涉网案件线索发现方式包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 556,
        "title": "下列属于侵犯公民个人信息行业黑话的有（  ）",
        "answer": "ABCD"
    },
    {
        "id": 557,
        "title": "以下哪种情况可以采取网络信息查询监控措施（  ）",
        "answer": "ABCD"
    },
    {
        "id": 558,
        "title": "网络在线提取电子数据时需要进一步查明下列情形之一的，应当进行网络远程勘验（  ）",
        "answer": "ABCD"
    },
    {
        "id": 559,
        "title": "舆情信息巡查境内常见通用搜索引擎有（  ）",
        "answer": "ABDEF"
    },
    {
        "id": 560,
        "title": "舆情信息巡查境外常用的搜索引擎有（  ）",
        "answer": "ACDE"
    },
    {
        "id": 561,
        "title": "高级检索功能。如百度搜索引擎中的“高级搜索”功能，能直观的对（  ）进行搜索。",
        "answer": "BCDEF"
    },
    {
        "id": 562,
        "title": "问答平台。主流的问答应用包括（  ）",
        "answer": "ABCE"
    },
    {
        "id": 563,
        "title": "拿到一张图片，利用搜索引擎的以图识图的功能，主要能够达到的目的是（  ）",
        "answer": "CE"
    },
    {
        "id": 564,
        "title": "舆情处置三同步原则是（  ）",
        "answer": "ABC"
    },
    {
        "id": 565,
        "title": "网络舆情信息巡查在公开网络社区和半公开网络社区中进行。公开网络社区主要为（  ）",
        "answer": "ABCDF"
    },
    {
        "id": 566,
        "title": "在新闻媒体首页进行浏览发现，或通过“Ctrl+F”进行关键词检索，关键词可以选择（  ），在首页显示的内容一般而言热度较高。",
        "answer": "CDE"
    },
    {
        "id": 567,
        "title": "一般论坛帖文排序会默认按照（  ）排序。",
        "answer": "AF"
    },
    {
        "id": 568,
        "title": "《刑法修正案（九）》设立的新罪名: 帮助信息网络犯罪活动罪第二百八十七条之二：明知他人利用信息网络实施犯罪，为其犯罪提供(    )等技术支持，或者提供广告推广、支付结算等帮助，情节严重的，处三年以下有期徒刑或者拘役，并处或者单处罚金。",
        "answer": "ABCD"
    },
    {
        "id": 569,
        "title": "对于网络用户利用网络服务实施侵权行为（  ）",
        "answer": "ABC"
    },
    {
        "id": 570,
        "title": "采集管控重点人员。对涉政敏感人员、涉恐关注人员、涉藏重点人员、涉访涉稳人员、易肇事肇祸精神病人、可能实施个人极端暴力犯罪人员、可能继续作案的重点刑事犯罪前科人员等重点高危人员及重点人(  )均要达到１００％的顶格标准。",
        "answer": "ACD"
    },
    {
        "id": 571,
        "title": "手提式灭火器的检查方法（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 572,
        "title": "公安派出所开展安全防范的主要方式包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 573,
        "title": "技防设施主要包括（  ）",
        "answer": "ACDE"
    },
    {
        "id": 574,
        "title": "校园安全防范要建立以（  ）为内容的五防建设体系。",
        "answer": "ABCEF"
    },
    {
        "id": 575,
        "title": "“1+3+N”专群联动模式中N支群防力量包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 576,
        "title": "“1+3+N”专群联动队伍开展的工作有（  ）",
        "answer": "ABCD"
    },
    {
        "id": 577,
        "title": "网格力量整合过程中，为提升网格员在岗履职能力，提升网格员职业素养，建立了“三训”机制。以下不是“三训”机制中的有（  ）",
        "answer": "AD"
    },
    {
        "id": 578,
        "title": "以下属于城乡社区发展治理单元行动的是（  ）",
        "answer": "BC"
    },
    {
        "id": 579,
        "title": "（  ）二代证工本费每证40元。",
        "answer": "CD"
    },
    {
        "id": 580,
        "title": "按公安部《关于做好停征首次申领居民身份证工本费有关工作的紧急通知》规定：从4月1日起，停征首次申领居民身份证工本费。停征范围包括（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 581,
        "title": "居民身份证登记的项目包括：姓名、性别、民族、出生日期（  ）",
        "answer": "ABCD"
    },
    {
        "id": 582,
        "title": "窗口工作人员日常工作中，下列哪些行为不规范（  ）",
        "answer": "ABCD"
    },
    {
        "id": 583,
        "title": "根据《<四川省流动人口信息登记办法>简易程序执法指引》（成公通【2017】83号）下列哪项可以采用当场处罚（  ）",
        "answer": "BCD"
    },
    {
        "id": 584,
        "title": "下列哪项必定属于公安机关管控的重性精神病患者（  ）",
        "answer": "BC"
    },
    {
        "id": 585,
        "title": "门（楼）牌原则上按3—5米编一个号，保持左单右双，顺序从小到大，杜绝（  ）的现象。",
        "answer": "ABCD"
    },
    {
        "id": 586,
        "title": "网络文化的特征有（   ）",
        "answer": "ABC"
    },
    {
        "id": 587,
        "title": "反映新闻业蜕变趋势的有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 588,
        "title": "（   ）是政务新媒体的禁区。",
        "answer": "ABCDE"
    },
    {
        "id": 589,
        "title": "在面对突发事件时，以下做法错误的是（   )",
        "answer": "ABC"
    },
    {
        "id": 590,
        "title": "自媒体有（   ）特点",
        "answer": "ABCD"
    },
    {
        "id": 591,
        "title": "新媒体时代，舆论监督的特点有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 592,
        "title": "正面信息发布大抵包含（   ）意思。",
        "answer": "ABCD"
    },
    {
        "id": 593,
        "title": "正面信息发布的方式有（   ）",
        "answer": "BCD"
    },
    {
        "id": 594,
        "title": "网络信息发布三大必备意识是（   ）",
        "answer": "ABC"
    },
    {
        "id": 595,
        "title": "对舆论引导和危机处置提出科学对策，需要把握的几个原则为（   ）",
        "answer": "ABCD"
    },
    {
        "id": 596,
        "title": "建立快速反应的舆论引导机制的方法有（   ）",
        "answer": "ACD"
    },
    {
        "id": 597,
        "title": "在新媒体时代，人人拥有麦克风，个个手握摄像头，都可以成为（   ）的主体。",
        "answer": "ABCD"
    },
    {
        "id": 598,
        "title": "新媒体时代的特点（   ）",
        "answer": "ABCD"
    },
    {
        "id": 599,
        "title": "网民积淀的社会心态包括有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 600,
        "title": "网民积淀的社会意识包括有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 601,
        "title": "公安宣传的封闭化、自闭化倾向几个陷阱有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 602,
        "title": "在开展舆论引导工作中，应尽量避免与哪些群体形成对立（   ）",
        "answer": "ABCD"
    },
    {
        "id": 603,
        "title": "突发社会公共事件政治议题类型中具体包含有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 604,
        "title": "突发社会公共事件社会议题类型中具体包含有（   ）",
        "answer": "ABCD"
    },
    {
        "id": 605,
        "title": "根据岗位的涉密程度，将涉密岗位分别确定为（   ）、（   ） 和（   ）涉密岗位。",
        "answer": "ABC"
    },
    {
        "id": 606,
        "title": "保密承诺书可分为（   ）、（   ）和（   ）保密承诺书等。",
        "answer": "ABC"
    },
    {
        "id": 607,
        "title": "对一份文件定密，应该由起草人拟定（   ）密级，定密责任人审核。",
        "answer": "ABCD"
    },
    {
        "id": 608,
        "title": "不得私自销毁涉密纸介质载体，不得将涉密纸介质载体（   ）",
        "answer": "ACD"
    },
    {
        "id": 609,
        "title": "不得在互联网和公安网计算机之间（   ）、（   ）和（   ）国家秘密信息。",
        "answer": "ABC"
    },
    {
        "id": 610,
        "title": "涉密人员发现针对本人（   ），（   ）和（   ）行为的,应当及时向机关、单位报告，书面填写《涉密人员重大事项报告表》。",
        "answer": "ACD"
    },
    {
        "id": 611,
        "title": "2017年10月4日,安徽省宣城市旌德县公安局指挥中心机要民警秦剑因突发腹痛去医院治疗,擅自委托辅警崔海燕代其值守机要。崔海燕收到《安徽省公安厅关于认真汲取美国拉斯维加斯枪击案教训切实加强反恐维稳安保工作的通知》（机密级）后，通过电话向指挥中心主任陈徽报告情况，并按照陈徽要求，用手机拍照至内部工作微信群。这起案件存在（   ）问题。",
        "answer": "ABC"
    },
    {
        "id": 612,
        "title": "“五眼联盟”国家即（   ）五国。",
        "answer": "ABCDE"
    },
    {
        "id": 613,
        "title": "禁止通过（   ）等无保密措施的渠道传递涉密文件信息资料。",
        "answer": "ABCD"
    },
    {
        "id": 614,
        "title": "公安大数据战略将助推公安工作产生（ ）等变革。",
        "answer": "ABC"
    },
    {
        "id": 615,
        "title": "以下对科技兴警和大数据智能化建设描述正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 616,
        "title": "大数据是一种规模大到在获取、存储、管理、分析方面大大超出了传统数据库软件工具能力范围的数据集合，（ ）属于大数据的特征。",
        "answer": "ABCD"
    },
    {
        "id": 617,
        "title": "5G是第五代移动通信技术的简称，是4G之后的新一代技术创新，（ ）是5G的特点。",
        "answer": "ABC"
    },
    {
        "id": 618,
        "title": "网络安全和信息化是一体之两翼、驱动之双轮，必须（ ）",
        "answer": "ABCD"
    },
    {
        "id": 619,
        "title": "属于世界科技发展趋势的有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 620,
        "title": "党的十九大进一步强调创新是引领发展的第一动力，加强应用基础研究，拓展实施国家重大科技项目，突出关键共性技术、前沿引领技术、现代工程技术、颠覆性技术创新，为建设（ ）交通强国、数字中国、智慧社会提供有力支撑。",
        "answer": "ABCD"
    },
    {
        "id": 621,
        "title": "面向经济社会发展主战场提供更多科技供给的有（ ）",
        "answer": "ABD"
    },
    {
        "id": 622,
        "title": "大数据的主要特征（ ）",
        "answer": "ABCD"
    },
    {
        "id": 623,
        "title": "区块链具有（ ）等特征。",
        "answer": "ABC"
    },
    {
        "id": 624,
        "title": "信息资源服务平台，按照统一的标准规范，依据信息共享目录汇集、整合公安内部、外部信息，提供（ ）的信息共享服务。",
        "answer": "ABC"
    },
    {
        "id": 625,
        "title": "信息共享目录包括( )",
        "answer": "ABCD"
    },
    {
        "id": 626,
        "title": "影响无线通信距离的主要性能指标有（ ）",
        "answer": "ABCD"
    },
    {
        "id": 627,
        "title": "无线通信接入方式（寻址方式）有（ ）等三种。",
        "answer": "ABC"
    },
    {
        "id": 628,
        "title": "公安信息网安全管理遵循（ ）、保障应用的原则。",
        "answer": "ABCD"
    },
    {
        "id": 629,
        "title": "按照《公安网用户必须遵守的“八条纪律”和“四个严禁”》的要求，其中“四个严禁”是（ ）和严禁在互联网上使用涉密移动存储介质。",
        "answer": "ABC"
    },
    {
        "id": 630,
        "title": "按照《关于禁止公安业务用计算机“一机两用”的通知》要求，以下说法正确的是（ ）",
        "answer": "ABCD"
    },
    {
        "id": 631,
        "title": "安全防范的三种基本手段是（ ）",
        "answer": "ABC"
    },
    {
        "id": 632,
        "title": "公安视频监控系统是指公安机关负责规划、（ ）的视频监控系统。",
        "answer": "ABCD"
    },
    {
        "id": 633,
        "title": "日常工作中民警对心理健康工作可能存在（  ）认识误区。",
        "answer": "ABCD"
    },
    {
        "id": 634,
        "title": "开展民警心理健康工作的意义在于（  ）",
        "answer": "ABCD"
    },
    {
        "id": 635,
        "title": "民警心理健康是经常的习惯性的（  ）与（  ）的一致。",
        "answer": "AD"
    },
    {
        "id": 636,
        "title": "民警不良情绪的危害有（  ）",
        "answer": "BCD"
    },
    {
        "id": 637,
        "title": "下列属于不良情绪自我调控方法的是（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 638,
        "title": "民警的压力源有(  )",
        "answer": "ABCD"
    },
    {
        "id": 639,
        "title": "压力的自我管理方法有(  )",
        "answer": "ABCDEF"
    },
    {
        "id": 640,
        "title": "（  ）情况较多出现时，应对心理健康情况予以关注。",
        "answer": "ABCDEF"
    },
    {
        "id": 641,
        "title": "下列属于恐惧症状表现的有（  ）",
        "answer": "ABEF"
    },
    {
        "id": 642,
        "title": "保持健康睡眠，做到睡眠卫生，可以在（  ）方面注意。",
        "answer": "ABCDE"
    },
    {
        "id": 643,
        "title": "失眠干预中调整自我认知方法要注意以下几点：（  ）",
        "answer": "AC"
    },
    {
        "id": 644,
        "title": "人际交往的基本原则（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 645,
        "title": "影响人际吸引的因素（  ）",
        "answer": "ABCD"
    },
    {
        "id": 646,
        "title": "民警教育子女的常用技巧（  ）",
        "answer": "ABCD"
    },
    {
        "id": 647,
        "title": "积极心态可以帮助我们（  ）",
        "answer": "ABCD"
    },
    {
        "id": 648,
        "title": "积极的心态包括（  ）",
        "answer": "ABCD"
    },
    {
        "id": 649,
        "title": "下列属于对遭遇阻碍执法行为时的处置原则的是（  ）",
        "answer": "BC"
    },
    {
        "id": 650,
        "title": "下列属于对嫌疑车辆盘查的总体原则的是（  ）",
        "answer": "AD"
    },
    {
        "id": 651,
        "title": "对嫌疑车辆盘查时，民警应当避免下列哪些情况发生（  ）",
        "answer": "ABCD"
    },
    {
        "id": 652,
        "title": "对嫌疑车辆盘查时，民警下列哪些做法符合安全要求（  ）",
        "answer": "ACD"
    },
    {
        "id": 653,
        "title": "民警下列做法符合盘查要求的是（  ）",
        "answer": "AB"
    },
    {
        "id": 654,
        "title": "QSZ92式9mm半自动手枪有哪些保险（  ）",
        "answer": "BCD"
    },
    {
        "id": 655,
        "title": "下列属于半自动手枪安全检查规范的是(  )",
        "answer": "ABCD"
    },
    {
        "id": 656,
        "title": "以下哪些方式可以释放击锤(  )",
        "answer": "BC"
    },
    {
        "id": 657,
        "title": "执法记录仪使用要求（  ）",
        "answer": "ABCD"
    },
    {
        "id": 658,
        "title": "枪支分解结合过程中哪些是错误的操作（  ）",
        "answer": "ABD"
    },
    {
        "id": 659,
        "title": "运用长棍戳击技术时，应注意（  ）",
        "answer": "ABC"
    },
    {
        "id": 660,
        "title": "当遇到刀斧砍杀等暴力案件时，以下哪些环境优先选用盾棍叉组合技术处置（  ）",
        "answer": "ABCDE"
    },
    {
        "id": 661,
        "title": "盾牌检查方法（  ）",
        "answer": "ABCD"
    },
    {
        "id": 662,
        "title": "长棍劈击应注意（  ）",
        "answer": "BCD"
    },
    {
        "id": 663,
        "title": "使用喷剂后下面哪些做法是正确的（  ）",
        "answer": "ABC"
    },
    {
        "id": 664,
        "title": "直臂控制动作需要控制哪些部位（  ）",
        "answer": "ABC"
    },
    {
        "id": 665,
        "title": "徒手控制中“多控一”的原则是（  ）",
        "answer": "ABC"
    },
    {
        "id": 666,
        "title": "关于倒地后的戒备动作，下列说法正确的是（  ）",
        "answer": "BCD"
    },
    {
        "id": 667,
        "title": "下列关于徒手控制技术说法正确的是（  ）",
        "answer": "CD"
    },
    {
        "id": 668,
        "title": "大力实施“一三五七”发展战略，奋力开创新时代四川公安工作新局面 ，其中“五”是（        ）",
        "answer": "BCDEF"
    },
    {
        "id": 669,
        "title": "中国共产党第二十次全国代表大会，是在全党全国各族人民迈上全面建设(      )、向第二个百年奋斗目标进军的关键时刻召开的一次十分重要的大会。",
        "answer": "A"
    },
    {
        "id": 670,
        "title": "要增强全党全国各族人民的志气、骨气、底气，不信邪、不怕鬼、不怕压，知难而进、迎难而上，全力战胜前进道路上各种困难和挑战，依靠顽强斗争打开事业发展（      ）。",
        "answer": "C"
    },
    {
        "id": 671,
        "title": "我们隆重庆祝中国共产党成立一百周年、中华人民共和国成立七十周年，制定第（    ）个历史决议，在全党开展党史学习教育。",
        "answer": "C"
    },
    {
        "id": 672,
        "title": "我们坚持（  ）为重、国内政治优先，保持战略定力，发扬斗争精神，展示不畏强权的坚定意志，在斗争中维护国家尊严和核心利益。",
        "answer": "A"
    },
    {
        "id": 673,
        "title": "要坚持马克思主义在意识形态领域指导地位的（   ），坚持为人民服务、为社会主义服务。",
        "answer": "C"
    },
    {
        "id": 674,
        "title": "必须增强忧患意识，坚持（   ），做到居安思危、未雨绸缪，准备经受风高浪急甚至惊涛骇浪的重大考验。",
        "answer": "C"
    },
    {
        "id": 675,
        "title": "我国一些关键核心技术实现突破，战略性新兴产业发展壮大，许多领域等取得重大成果，进入（   ）国家行列。",
        "answer": "A"
    },
    {
        "id": 676,
        "title": "以巨大的政治勇气全面深化改革，许多领域实现历史性变革、系统性重塑、整体性重构，中国特色社会主义制度（  ），国家治理体系和治理能力现代化水平明显提高。",
        "answer": "D"
    },
    {
        "id": 677,
        "title": "我们全面加强党的领导，确保党中央权威和集中统一领导，确保党发挥（   ）的领导核心作用。",
        "answer": "B"
    },
    {
        "id": 678,
        "title": "加强宪法（ ），健全保证宪法全面实施的制度体系，更好发挥宪法在治国理政中的重要作用。",
        "answer": "A"
    },
    {
        "id": 679,
        "title": "以城市群、都市圈为依托构建大中小城市协调发展格局，推进以（   ）为重要载体的城镇化建设。\n\n",
        "answer": "A"
    },
    {
        "id": 680,
        "title": "在新中国成立特别是改革开放以来长期探索和实践基础上，经过十八大以来在理论和实践上的创新突破，我们党成功推进和拓展了（   ）。",
        "answer": "B"
    },
    {
        "id": 681,
        "title": "我们党作为世界上最大的（   ），要始终赢得人民拥护、巩固长期执政地位，必须时刻保持解决大党独有难题的清醒和坚定。",
        "answer": "C"
    },
    {
        "id": 682,
        "title": "中国式现代化，是中国共产党领导的社会主义现代化，既有各国现代化的共同特征，更有基于自己国情的（    ）。",
        "answer": "B"
    },
    {
        "id": 683,
        "title": "要坚持马克思主义在意识形态领域指导地位的（   ），坚持为人民服务、为社会主义服务。",
        "answer": "C"
    },
    {
        "id": 684,
        "title": "必须牢固树立和践行绿水青山就是金山银山的理念，站在人与自然（   ）的高度谋划发展。",
        "answer": "B"
    },
    {
        "id": 685,
        "title": "贯彻总体国家安全观，以坚定的意志品质维护国家主权、安全、（），国家安全得到全面加强。",
        "answer": "D"
    },
    {
        "id": 686,
        "title": "人心是最大的政治，（  ）是凝聚人心、汇聚力量的强大法宝。",
        "answer": "B"
    },
    {
        "id": 687,
        "title": "我们创立了新时代中国特色社会主义思想，明确坚持和发展中国特色社会主义的基本方略，提出一系列治国理政新理念新思想新战略，实现了马克思主义（ ）新的飞跃。",
        "answer": "B"
    },
    {
        "id": 688,
        "title": "（  ）是我们立党立国、兴党兴国的根本指导思想。",
        "answer": "A"
    },
    {
        "id": 689,
        "title": "满足人民日益增长的（  ），巩固全党全国各族人民团结奋斗的共同思想基础，不断提升国家文化软实力和中华文化影响力。",
        "answer": "B"
    },
    {
        "id": 690,
        "title": "推行草原森林河流湖泊湿地休养生息，实施好长江（   ）禁渔，健全耕地休耕轮作制度。",
        "answer": "D"
    },
    {
        "id": 691,
        "title": "我们要推进（ ），坚持山水林田湖草沙一体化保护和系统治理，统筹产业结构调整、污染治理、生态保护、应对气候变化。",
        "answer": "A"
    },
    {
        "id": 692,
        "title": "我们要办好人民满意的教育，全面贯彻党的教育方针，落实立德树人（    ），培养德智体美劳全面发展的社会主义建设者和接班人。",
        "answer": "D"
    },
    {
        "id": 693,
        "title": "如期实现建军一百年奋斗目标，加快把人民军队建成世界一流军队，是全面建设社会主义现代化国家的（   ）。",
        "answer": "C"
    },
    {
        "id": 694,
        "title": "面对香港局势动荡变化，我们依照宪法和基本法有效实施对特别行政区的（ ），制定实施香港特别行政区维护国家安全法，落实“爱国者治港”原则，香港局势实现由乱到治的重大转折。",
        "answer": "D"
    },
    {
        "id": 695,
        "title": "中国特色社会主义最本质的特征是（）。",
        "answer": "C"
    },
    {
        "id": 696,
        "title": "改革开放和社会主义现代化建设深入推进，实现中华民族伟大复兴进入了（   ）的历史进程。",
        "answer": "B"
    },
    {
        "id": 697,
        "title": "推动（ ）融合集群发展，构建新一代信息技术、人工智能、生物技术、新能源、新材料、高端装备、绿色环保等一批新的增长引擎。",
        "answer": "C"
    },
    {
        "id": 698,
        "title": "面对国际局势急剧变化，我们保持战略定力，发扬斗争精神，在斗争中维护国家尊严和（   ）利益，牢牢掌握了我国发展和安全主动权。",
        "answer": "D"
    },
    {
        "id": 699,
        "title": "（ ）是社会主义的生命，是全面建设社会主义现代化国家的应有之义。",
        "answer": "B"
    },
    {
        "id": 700,
        "title": "（   ）是立党为公、执政为民的本质要求。",
        "answer": "D"
    },
    {
        "id": 701,
        "title": "必须持之以恒推进全面从严治党，深入推进新时代党的建设新的伟大工程，以党的（  ）引领社会革命。",
        "answer": "B"
    },
    {
        "id": 702,
        "title": "（ ）是民族复兴的根基，社会稳定是国家强盛的前提。",
        "answer": "B"
    },
    {
        "id": 703,
        "title": "以党的（   ）统领党的建设各项工作，坚持思想建党和制度治党同向发力，严肃党内政治生活。",
        "answer": "A"
    },
    {
        "id": 704,
        "title": "全面建设社会主义现代化国家、全面推进中华民族伟大复兴，关键在（  ）。",
        "answer": "A"
    },
    {
        "id": 705,
        "title": "经过不懈努力，党找到了（ ）这一跳出治乱兴衰历史周期率的第二个答案，确保党永远不变质、不变色、不变味。",
        "answer": "B"
    },
    {
        "id": 706,
        "title": "中国外交政策宗旨是（ ）。",
        "answer": "C"
    },
    {
        "id": 707,
        "title": "发挥国家发展规划的战略导向作用，加强财政政策和货币政策协调配合，着力扩大内需，增强（   ）对经济发展的基础性作用和投资对优化供给结构的关键作用。",
        "answer": "B"
    },
    {
        "id": 708,
        "title": "全方位夯实粮食安全根基，牢牢守住（ ）亩耕地红线。",
        "answer": "C"
    },
    {
        "id": 709,
        "title": "坚持我国宗教（   ）方向，积极引导宗教与社会主义社会相适应。",
        "answer": "D"
    },
    {
        "id": 710,
        "title": "十八大以来，我们党勇于进行理论探索和创新，以全新的视野深化对共产党执政规律、社会主义建设规律、人类社会发展规律的认识，取得重大理论创新成果，集中体现为（ ）。",
        "answer": "A"
    },
    {
        "id": 711,
        "title": "完善科技创新体系，坚持（  ）在我国现代化建设全局中的核心地位。",
        "answer": "C"
    },
    {
        "id": 712,
        "title": "以铸牢（  ）意识为主线，加强和改进党的民族工作。",
        "answer": "B"
    },
    {
        "id": 713,
        "title": "我们必须增强忧患意识，坚持（  ），做到居安思危、未雨绸缪，准备经受风高浪急甚至惊涛骇浪的重大考验。",
        "answer": "C"
    },
    {
        "id": 714,
        "title": "坚持（ ），建立大安全大应急框架，完善公共安全体系，推动公共安全治理模式向事前预防转型。",
        "answer": "D"
    },
    {
        "id": 715,
        "title": "二十大报告提出，创新和改进领导方式，提高党把方向、谋大局、定政策、（    ）能力，调动各方面积极性。",
        "answer": "A"
    },
    {
        "id": 716,
        "title": "面对“台独”势力分裂活动和外部势力干涉台湾事务的严重挑衅，党和国家坚决开展反分裂、反干涉重大斗争，展示了我们维护国家主权和领土完整、反对“台独”的（   ）。",
        "answer": "D"
    },
    {
        "id": 717,
        "title": "（ ）是党执政兴国的第一要务。没有坚实的物质技术基础，就不可能全面建成社会主义现代化强国。",
        "answer": "A"
    },
    {
        "id": 718,
        "title": "二十大报告提出，完善社会治理体系。要加快推进（  ）社会治理现代化，提高市域社会治理能力。",
        "answer": "C"
    },
    {
        "id": 719,
        "title": "必须坚持党对人民军队的绝对领导,坚持政治建军、改革强军、科技强军、(   )、依法治军。",
        "answer": "C"
    },
    {
        "id": 720,
        "title": "发挥我国（     ）优势，坚持长期共存、互相监督、肝胆相照、荣辱与共，加强同民主党派和无党派人士的团结合作，支持民主党派加强自身建设、更好履行职能。",
        "answer": "D"
    },
    {
        "id": 721,
        "title": "坚持社会主义市场经济改革方向，坚持高水平对外开放，加快构建以国内大循环为（  )、国内国际双循环相互促进的新发展格局。",
        "answer": "A"
    },
    {
        "id": 722,
        "title": "推进（ ）建设，支持香港、澳门更好融入国家发展大局，为实现中华民族伟大复兴更好发挥作用。",
        "answer": "D"
    },
    {
        "id": 723,
        "title": "发展乡村特色产业，拓宽农民增收致富渠道。巩固拓展脱贫攻坚成果，增强脱贫地区和脱贫群众（     ）。",
        "answer": "C"
    },
    {
        "id": 724,
        "title": "加强（ ）体系建设，塑造主流舆论新格局。健全网络综合治理体系，推动形成良好网络生态。",
        "answer": "D"
    },
    {
        "id": 725,
        "title": "坚决打赢反腐败斗争攻坚战持久战。坚持不敢腐、不能腐、不想腐（   ），同时发力、同向发力、综合发力。",
        "answer": "C"
    },
    {
        "id": 726,
        "title": "建设现代化产业体系。坚持把发展经济的着力点放在（ ）上，推进新型工业化。",
        "answer": "D"
    },
    {
        "id": 727,
        "title": "我们坚持团结广大台湾同胞，坚定支持岛内爱国统一力量，共同把握历史大势，坚守民族大义，坚定（   ）。",
        "answer": "B"
    },
    {
        "id": 728,
        "title": "未来五年是全面建设社会主义现代化国家开局起步的（   ）。",
        "answer": "C"
    },
    {
        "id": 729,
        "title": "深入实施科教兴国战略、人才强国战略、创新驱动发展战略，开辟发展（  ）新赛道，不断塑造发展新动能新优势。",
        "answer": "D"
    },
    {
        "id": 730,
        "title": "要建设具有强大凝聚力和引领力的社会主义意识形态，牢牢掌握党对意识形态工作（   ），全面落实意识形态工作责任制。",
        "answer": "A"
    },
    {
        "id": 731,
        "title": "要深入实施人才强国战略，培养造就大批（    ）的高素质人才，是国家和民族长远发展大计。",
        "answer": "C"
    },
    {
        "id": 732,
        "title": "全党要坚持全心全意为人民服务的（  ），树牢群众观点，贯彻群众路线。",
        "answer": "B"
    },
    {
        "id": 733,
        "title": "十八大以来，经历了对党和人民事业具有重大现实意义和深远历史意义的三件大事，这是中国共产党和中国人民团结奋斗赢得的（  ），是彪炳中华民族发展史册的历史性胜利，也是对世界具有深远影响的历史性胜利。",
        "answer": "B"
    },
    {
        "id": 734,
        "title": "推动货物贸易优化升级，创新服务贸易发展机制，发展数字贸易，加快建设（   ）。",
        "answer": "D"
    },
    {
        "id": 735,
        "title": "中国奉行（ ）的国防政策，中国的发展是世界和平力量的增长，无论发展到什么程度，中国永远不称霸、永远不搞扩张。",
        "answer": "B"
    },
    {
        "id": 736,
        "title": "中国坚持在（   ）基础上同各国发展友好合作，推动构建新型国际关系，深化拓展平等、开放、合作的全球伙伴关系，致力于扩大同各国利益的汇合点。",
        "answer": "C"
    },
    {
        "id": 737,
        "title": "落实全面从严治党政治责任，用好（ ）利器。",
        "answer": "D"
    },
    {
        "id": 738,
        "title": "建设堪当民族复兴重任的高素质干部队伍，坚持德才兼备、（）、五湖四海、任人唯贤。",
        "answer": "D"
    },
    {
        "id": 739,
        "title": "各级党组织要履行党章赋予的各项职责，把（   ）贯彻落实好，把各领域广大群众组织凝聚好。",
        "answer": "B"
    },
    {
        "id": 740,
        "title": "完善权力监督制约机制，以（）为主导，促进各类监督贯通协调，让权力在阳光下运行。",
        "answer": "A"
    },
    {
        "id": 741,
        "title": "（  ）是维护社会公平正义的最后一道防线。",
        "answer": "C"
    },
    {
        "id": 742,
        "title": "必须牢固树立和践行（ )的理念，站在人与自然和谐共生的高度谋划发展。",
        "answer": "D"
    },
    {
        "id": 743,
        "title": "全面加强国家安全教育，提高各级领导干部统筹发展和安全能力，增强全民国家安全意识和素养，筑牢国家安全（   ）。",
        "answer": "D"
    },
    {
        "id": 744,
        "title": "中国愿加大对全球发展合作的资源投入，致力于缩小（   ），坚定支持和帮助广大发展中国家加快发展。",
        "answer": "C"
    },
    {
        "id": 745,
        "title": "中国坚持积极参与全球安全规则制定，加强国际安全合作，积极参与联合国维和行动，为维护世界和平和地区稳定发挥（    ）。",
        "answer": "C"
    },
    {
        "id": 746,
        "title": "建设中国特色社会主义法治体系、建设社会主义法治国家，围绕保障和促进（   ），坚持依法治国、依法执政、依法行政共同推进。",
        "answer": "A"
    },
    {
        "id": 747,
        "title": "完善以宪法为核心的中国特色社会主义法律体系，加强宪法实施和监督，加强重点领域、（）领域、涉外领域立法。",
        "answer": "B"
    },
    {
        "id": 748,
        "title": "坚定奉行（  ）的开放战略，不断以中国新发展为世界提供新机遇，推动建设开放型世界经济，更好惠及各国人民。",
        "answer": "C"
    },
    {
        "id": 749,
        "title": "巩固提高一体化国家战略体系和能力。加强军地战略规划统筹、政策制度衔接、资源要素（  ）。",
        "answer": "C"
    },
    {
        "id": 750,
        "title": "弘扬以（）为源头的中国共产党人精神谱系，深入开展社会主义核心价值观宣传教育。",
        "answer": "C"
    },
    {
        "id": 751,
        "title": "繁荣发展文化事业和文化产业，要坚持以（）的创作导向，推出更多增强人民精神力量的优秀作品。",
        "answer": "B"
    },
    {
        "id": 752,
        "title": "健全社会保障体系，健全(   ）、统筹城乡、公平统一、安全规范、可持续的多层次社会保障体系，扩大社会保险覆盖面。",
        "answer": "D"
    },
    {
        "id": 753,
        "title": "坚持和完善“一国两制”制度体系，落实中央全面管治权，落实“爱国者治港”、“爱国者治澳”原则，落实特别行政区维护国家安全的（   )和执行机制。",
        "answer": "C"
    },
    {
        "id": 754,
        "title": "推进健康中国建设，要把保障（  ）放在优先发展的战略位置，完善人民健康促进政策。",
        "answer": "C"
    },
    {
        "id": 755,
        "title": "党的二十大报告指出，要推进教育（ ），建设全民终身学习的学习型社会、学习型大国。",
        "answer": "D"
    },
    {
        "id": 756,
        "title": "要优化（ ）资源配置，强化学前教育、特殊教育普惠发展，坚持高中阶段学校多样化发展，完善覆盖全学段学生资助体系。",
        "answer": "B"
    },
    {
        "id": 757,
        "title": "实施全面节约战略，推进各类资源节约集约利用，加快构建（   ）体系。",
        "answer": "C"
    },
    {
        "id": 758,
        "title": "深化国资国企改革，加快国有经济布局优化和结构调整，推动国有资本和国有企业做强做优做大，提升（      ）",
        "answer": "C"
    },
    {
        "id": 759,
        "title": "党的二十大报告指出，深入开展健康中国行动和( )，倡导文明健康生活方式。",
        "answer": "B"
    },
    {
        "id": 760,
        "title": "坚持（ ）的鲜明导向，抓党建促乡村振兴，加强城市社区党建工作，推进以党建引领基层治理，持续整顿软弱涣散基层党组织，把基层党组织建设成为有效实现党的领导的坚强战斗堡垒。",
        "answer": "A"
    },
    {
        "id": 761,
        "title": "要加快建设（ ）力量，努力培养造就更多大师、战略科学家、一流科技领军人才和创新团队、青年科技人才、卓越工程师、大国工匠、高技能人才。",
        "answer": "D"
    },
    {
        "id": 762,
        "title": "深入实施种业振兴行动，强化农业科技和装备支撑，健全(    )保障机制和主产区利益补偿机制，确保中国人的饭碗牢牢端在自己手中。",
        "answer": "A"
    },
    {
        "id": 763,
        "title": "全面加强人民军队（ ），确保枪杆子永远听党指挥。",
        "answer": "B"
    },
    {
        "id": 764,
        "title": "中国始终坚持维护世界和平、促进共同发展的外交政策宗旨，致力于推动构建（    ）。",
        "answer": "D"
    },
    {
        "id": 765,
        "title": "深化反腐败（    ），一体构建追逃防逃追赃机制。",
        "answer": "D"
    },
    {
        "id": 766,
        "title": "中国坚持在和平共处五项原则基础上同各国发展友好合作，推动构建新型国际关系，深化拓展（   )、开放、合作的全球伙伴关系。",
        "answer": "B"
    },
    {
        "id": 767,
        "title": "党的二十大报告指出，中国秉持真实亲诚理念和正确义利观加强同（ ）团结合作，维护发展中国家共同利益。 ",
        "answer": "C"
    },
    {
        "id": 768,
        "title": "加强反垄断和反不正当竞争，破除（ ）和行政性垄断，依法规范和引导资本健康发展。",
        "answer": "A"
    },
    {
        "id": 769,
        "title": "坚持（ ）一起抓，从思想上固本培元，提高党性觉悟，增强拒腐防变能力，涵养富贵不能淫、贫贱不能移、威武不能屈的浩然正气。",
        "answer": "C"
    },
    {
        "id": 770,
        "title": "（  ）是党的优势所在、力量所在。",
        "answer": "D"
    },
    {
        "id": 771,
        "title": "中国坚定奉行（   ）的和平外交政策，始终根据事情本身的是非曲直决定自己的立场和政策，维护国际关系基本准则，维护国际公平正义。",
        "answer": "B"
    },
    {
        "id": 772,
        "title": "我们要实现好、维护好、发展好最广大人民根本利益，紧紧抓住人民最关心最直接最现实的利益问题，坚持尽力而为、量力而行,(    )。",
        "answer": "C"
    },
    {
        "id": 773,
        "title": "坚持把（ ）放在首位，做深做实干部政治素质考察，突出把好政治关、廉洁关。",
        "answer": "A"
    },
    {
        "id": 774,
        "title": "党的二十大报告指出，努力提高居民收入在国民收入分配中的比重，提高（ ）在初次分配中的比重。",
        "answer": "B"
    },
    {
        "id": 775,
        "title": "立足我国能源资源禀赋，坚持（ ），有计划分步骤实施碳达峰行动。",
        "answer": "D"
    },
    {
        "id": 776,
        "title": "实现碳达峰碳中和是一场广泛而深刻的（）变革。",
        "answer": "D"
    },
    {
        "id": 777,
        "title": "坚持不懈用新时代中国特色社会主义思想凝心铸魂，全面加强党的（），引导全党牢记党的宗旨，自觉做共产主义远大理想和中国特色社会主义共同理想的坚定信仰者和忠实实践者。",
        "answer": "B"
    },
    {
        "id": 778,
        "title": "党的二十大报告指出，促进优质医疗资源扩容和区域均衡布局，坚持（  ）为主，加强重大慢性病健康管理，提高基层防病治病和健康管理能力。",
        "answer": "C"
    },
    {
        "id": 779,
        "title": "坚持党中央对国家安全工作的集中统一领导，完善（   ）的国家安全领导体制。",
        "answer": "B"
    },
    {
        "id": 780,
        "title": "发展壮大爱国爱港爱澳力量，增强港澳同胞的（   ），形成更广泛的国内外支持“一国两制”的统一战线。",
        "answer": "D"
    },
    {
        "id": 781,
        "title": "巩固和发展最广泛的（  ），完善大统战工作格局，坚持大团结大联合，动员全体中华儿女围绕实现中华民族伟大复兴中国梦一起来想、一起来干。",
        "answer": "C"
    },
    {
        "id": 782,
        "title": "以铸牢（ ）意识为主线，坚定不移走中国特色解决民族问题的正确道路，坚持和完善民族区域自治制度。",
        "answer": "B"
    },
    {
        "id": 783,
        "title": "（ ）是全面依法治国的重点任务和主体工程。",
        "answer": "A"
    },
    {
        "id": 784,
        "title": "坚守中华文化立场，提炼展示中华文明的精神标识和文化精髓，加快构建中国话语和中国叙事体系，（     ）、传播好中国声音，展现可信、可爱、可敬的中国形象。",
        "answer": "B"
    },
    {
        "id": 785,
        "title": "强化行政执法监督机制和能力建设，严格落实行政执法（   ）制和责任追究制度。",
        "answer": "D"
    },
    {
        "id": 786,
        "title": "统筹城乡就业政策体系，破除妨碍劳动力、人才流动的体制和政策弊端，消除影响平等就业的不合理限制和（  ），使人人都有通过勤奋劳动实现自身发展的机会。",
        "answer": "B"
    },
    {
        "id": 787,
        "title": "健全就业公共服务体系，完善（   ）就业支持体系，加强困难群体就业兜底帮扶。",
        "answer": "C"
    },
    {
        "id": 788,
        "title": "在推进全面依法治国进程中，要发挥（ ）示范带头作用，努力使尊法学法守法用法在全社会蔚然成风。",
        "answer": "C"
    },
    {
        "id": 789,
        "title": "推动理想信念教育常态化制度化，持续抓好党史、新中国史、（）、社会主义发展史宣传教育，引导人民知史爱党、知史爱国，不断坚定中国特色社会主义共同理想。",
        "answer": "B"
    },
    {
        "id": 790,
        "title": "坚持依法治国和以德治国相结合，把社会主义核心价值观融入法治建设、融入社会发展、融入（       ）。",
        "answer": "D"
    },
    {
        "id": 791,
        "title": "实施（）工程，弘扬中华传统美德，加强家庭家教家风建设，加强和改进未成年人思想道德建设。",
        "answer": "A"
    },
    {
        "id": 792,
        "title": "解决台湾问题、实现祖国完全统一，是党矢志不渝的历史任务，是全体中华儿女的共同愿望，是实现（   ）的必然要求。",
        "answer": "C"
    },
    {
        "id": 793,
        "title": "坚持（ ）是最高政治原则，系统完善党的领导制度体系，全党增强“四个意识”，自觉在思想上政治上行动上同党中央保持高度一致。",
        "answer": "B"
    },
    {
        "id": 794,
        "title": " （  ）是人民生活的安全网和社会运行的稳定器。",
        "answer": "C"
    },
    {
        "id": 795,
        "title": "党的二十大报告指出，建强人民军队党的组织体系，推进政治整训（   ），持之以恒正风肃纪反腐。",
        "answer": "B"
    },
    {
        "id": 796,
        "title": "坚持用新时代中国特色社会主义思想统一思想、统一意志、统一行动，组织实施党的创新理论学习教育计划，建设马克思主义（     ）政党。",
        "answer": "D"
    },
    {
        "id": 797,
        "title": "全党必须增强党组织的政治功能和（  ），培养选拔党和人民需要的好干部，培养和造就大批堪当时代重任的社会主义事业接班人",
        "answer": "B"
    },
    {
        "id": 798,
        "title": "我们必须坚定历史自信、文化自信，坚持古为今用、推陈出新，把马克思主义思想精髓同（   ）贯通起来、同人民群众日用而不觉的共同价值观念融通起来，不断赋予科学理论鲜明的中国特色，不断夯实马克思主义中国化时代化的历史基础和群众基础。",
        "answer": "B"
    },
    {
        "id": 799,
        "title": "党的二十大报告指出，完善个人所得税制度，规范（   ），规范财富积累机制，保护合法收入，调节过高收入，取缔非法收入。",
        "answer": "B"
    },
    {
        "id": 800,
        "title": "中国共产党和中国人民为解决人类面临的共同问题提供更多更好的（），为人类和平与发展崇高事业作出新的更大的贡献。",
        "answer": "A"
    },
    {
        "id": 801,
        "title": "坚持房子是用来住的、不是用来炒的定位，加快建立多主体供给、多渠道保障、（）的住房制度。",
        "answer": "D"
    },
    {
        "id": 802,
        "title": "深入推进改革创新，坚定不移扩大开放，着力破解深层次体制机制障碍，不断彰显（     ），不断增强社会主义现代化建设的动力和活力，把我国制度优势更好转化为国家治理效能。",
        "answer": "C"
    },
    {
        "id": 803,
        "title": "维护人民根本利益，增进民生福祉，不断实现发展为了人民、发展依靠人民、发展成果由人民共享，让（  ）更多更公平惠及全体人民。",
        "answer": "B"
    },
    {
        "id": 804,
        "title": "巩固和完善农村基本经营制度，发展新型农村集体经济，发展新型农业经营主体和社会化服务，发展农业（   ）。",
        "answer": "C"
    },
    {
        "id": 805,
        "title": "加快发展数字经济，促进数字经济和（  ）深度融合，打造具有国际竞争力的数字产业集群。",
        "answer": "B"
    },
    {
        "id": 806,
        "title": "坚持人民城市人民建、人民城市为人民，提高城市规划、建设、治理水平，加快转变（    ），实施城市更新行动，加强城市基础设施建设，打造宜居、韧性、智慧城市。",
        "answer": "D"
    },
    {
        "id": 807,
        "title": "到二〇三五年，我国建成现代化经济体系，形成新发展格局，基本实现新型工业化、信息化、城镇化、（）。",
        "answer": "B"
    },
    {
        "id": 808,
        "title": "坚持和完善我国根本政治制度、基本政治制度、重要政治制度，拓展民主渠道，丰富民主形式，确保人民依法通过（     ）管理国家事务，管理经济和文化事业，管理社会事务。",
        "answer": "C"
    },
    {
        "id": 809,
        "title": "我们要加快建设（）体系，着力提高全要素生产率，着力提升产业链供应链韧性和安全水平，着力推进城乡融合和区域协调发展，推动经济实现质的有效提升和量的合理增长。",
        "answer": "D"
    },
    {
        "id": 810,
        "title": "坚持制度治党、依规治党，以党章为根本，以（  ）为核心，完善党内法规制度体系，增强党内法规权威性和执行力，形成坚持真理、修正错误，发现问题、纠正偏差的机制。",
        "answer": "C"
    },
    {
        "id": 811,
        "title": "（ ）是社会主义民主政治的本质属性，是最广泛、最真实、最管用的民主。",
        "answer": "C"
    },
    {
        "id": 812,
        "title": "要完善科技创新体系。坚持创新在我国现代化建设全局中的（）。",
        "answer": "A"
    },
    {
        "id": 813,
        "title": "我国改革发展稳定面临不少深层次矛盾躲不开、绕不过，党的建设特别是党风廉政建设和反腐败斗争面临不少顽固性、多发性问题，来自（  ）随时可能升级。",
        "answer": "D"
    },
    {
        "id": 814,
        "title": "深化（ ），真心爱才、悉心育才、倾心引才、精心用才，求贤若渴，不拘一格，把各方面优秀人才集聚到党和人民事业中来。",
        "answer": "A"
    },
    {
        "id": 815,
        "title": "我国是一个发展中大国，仍处于（   ），正在经历广泛而深刻的社会变革，推进改革发展、调整利益关系往往牵一发而动全身。",
        "answer": "B"
    },
    {
        "id": 816,
        "title": "深化（）改革，深化科技评价改革，加大多元化科技投入，加强知识产权法治保障，形成支持全面创新的基础制度。",
        "answer": "B"
    },
    {
        "id": 817,
        "title": "坚决维护党中央（  ），把党的领导落实到党和国家事业各领域各方面各环节，使党始终成为风雨来袭时全体人民最可靠的主心骨，确保我国社会主义现代化建设正确方向，确保拥有团结奋斗的强大政治凝聚力、发展自信心。",
        "answer": "A"
    },
    {
        "id": 818,
        "title": "坚持和完善社会主义基本经济制度，毫不动摇巩固和发展公有制 ，毫不动摇鼓励、支持、引导（   ）经济发展，充分发挥市场在资源配置中的决定性作用，更好发挥政府作用。",
        "answer": "B"
    },
    {
        "id": 819,
        "title": "根据《宪法》规定，制定形式、民事、国家机构的和其他的基本法律的机关是（ ）",
        "answer": "B"
    },
    {
        "id": 820,
        "title": "刘某系全国人民代表大会代表，因涉嫌犯罪被批准逮捕。在全国人民代表大会必会期间，非经（ ）许可，刘某不受逮捕或者刑事审判。",
        "answer": "D"
    },
    {
        "id": 821,
        "title": "下列不属于公民政治自由的是（ ）",
        "answer": "D"
    },
    {
        "id": 822,
        "title": "张某因犯罪被判处拘役，由（ ）执行。",
        "answer": "C"
    },
    {
        "id": 823,
        "title": "精神病人华某肇事，严重危害他人人身安全，公安民警可以对其（ ）",
        "answer": "C"
    },
    {
        "id": 824,
        "title": "关于人民警察的义务，下列做法不正确的是（ ）",
        "answer": "C"
    },
    {
        "id": 825,
        "title": "下列可以使用继续盘问的情形是（ ）",
        "answer": "D"
    },
    {
        "id": 826,
        "title": "公安派出所民警王某2月1日9时在巡逻时发现一男青年形迹可疑，于是上前盘问，9时20分检查时发现其包里携带T型开锁器，10时公安派出所民警将其带到公安派出所继续盘问，11时将其关进候问室。对被盘问人的继续盘问时间不应超过（ ）",
        "answer": "D"
    },
    {
        "id": 827,
        "title": "系列有关民警的做法正确的是（ ）",
        "answer": "A"
    },
    {
        "id": 828,
        "title": "下列人员应聘到公安机关担任人民警察，其中不应当被录用的是（ ）",
        "answer": "B"
    },
    {
        "id": 829,
        "title": "张某打着介绍婚姻的幌子，不顾妇女甲的反对，强行将其出卖给男子乙。对张某应当以（ ）追究刑事责任。",
        "answer": "A"
    },
    {
        "id": 830,
        "title": "下列行为中不属于在公共交通工具上抢劫的是（ ）",
        "answer": "C"
    },
    {
        "id": 831,
        "title": "下列关于网络电信诈骗犯罪，说法正确的是（ ）",
        "answer": "C"
    },
    {
        "id": 832,
        "title": "张某明知陈某设置虚假网络，利用“木马”程序链接实施诈骗，仍为其提供网络存储和通讯传输的技术支持。王某明知陈某通过电信网路诈骗获得3万元，仍通过使用POS机刷卡套现，协助其转移财物。下列说法错误的是（ ）",
        "answer": "B"
    },
    {
        "id": 833,
        "title": "犯罪嫌疑人将电信网络诈骗财物用于清偿债务或者转让给他人后，不予追缴的是（ ）",
        "answer": "D"
    },
    {
        "id": 834,
        "title": "李某违反国家规定，多次从我国境内运输制造毒品原料的三氯甲烷至东南亚国家，共计30千克。李某构成（ ）",
        "answer": "C"
    },
    {
        "id": 835,
        "title": "某群众在马路上见到一个钱包（内有人民币3万余元），送交执勤民警寻找失主。民警寻找失主未果，乘机据为己有。民警的行为构成（ ）",
        "answer": "B"
    },
    {
        "id": 836,
        "title": "民警甲与朋友乙约定，由乙收取他人财物5万元，甲负责为请托人办理取保候审手续。下列说法正确的是（ ）",
        "answer": "B"
    },
    {
        "id": 837,
        "title": "甲将人打成重伤，侦办此案的民警乙与甲是好朋友，乙明知甲有罪，但碍于情面，采取毁灭证据的手段使甲未受追诉。乙构成（ ）",
        "answer": "B"
    },
    {
        "id": 838,
        "title": "公安派出所民警甲将涉嫌故意杀人罪的犯罪嫌疑人王某带出看守所进行现场实体辨认，其间，民警甲和同事至超市买香烟，将王某一人拷在车厢内，王某趁机挣脱手铐逃脱。甲的行为构成（ ）",
        "answer": "C"
    },
    {
        "id": 839,
        "title": "下列行为中，应当数罪并罚的是（ ）",
        "answer": "C"
    },
    {
        "id": 840,
        "title": "王某是“法轮功”的骨干成员，纠集数十名“法轮功”顽固分子，到某市人民法院附近公开进行邪教滋事活动，企图干扰审判工作。王某的行为构成（ ）",
        "answer": "C"
    },
    {
        "id": 841,
        "title": "张某是邪教组织的头目，某日在其煽动下，5名邪教组织成员在平房内服农药自杀。张某构成（ ）",
        "answer": "A"
    },
    {
        "id": 842,
        "title": "张某捏造了与某公司虚假的借贷关系并提起民事诉讼，非法占有该公司财物500万元。张某的行为构成（ ）",
        "answer": "C"
    },
    {
        "id": 843,
        "title": "新浪微博知名博主刘某，在与朋友律师王某聊天时，知悉其最近代理的一起涉及某上市公司商业秘密民事案件的庭审信息。于是，刘某将所知悉的案件庭审信息，发布在本人的新浪微博上，造成案件庭审信息广泛传播、10余家上市公司股价大幅下跌。刘某的行为构成（ ）",
        "answer": "A"
    },
    {
        "id": 844,
        "title": "张某未取得医生执业资格，但经常在某药店为他人看病，获利1000元。经查，其已经2次受到该县卫生局的行政处罚。张某的行为构成（ ）",
        "answer": "A"
    },
    {
        "id": 845,
        "title": "甲是谋社会第三方环境影响评价机构的工作人员，其舅舅乙经营的水泥厂排放有毒废物，严重污染环境。为逃避处罚，甲为乙提供虚假环境影响评价文件。甲构成（ ）",
        "answer": "C"
    },
    {
        "id": 846,
        "title": "王某经营一家进出口贸易公司，其以原料利用为名，从日本进口不能作原料的固体废物，情节严重。王某的行为构成（ ）",
        "answer": "B"
    },
    {
        "id": 847,
        "title": "孙某明知李某非法采矿，仍代为销售其矿产品。孙某的行为构成（ ）",
        "answer": "C"
    },
    {
        "id": 848,
        "title": "下列人员中不构成容留他人吸毒罪的是（ ）",
        "answer": "D"
    },
    {
        "id": 849,
        "title": "公安派出所所长郑某擅自将他人交纳的取保候审保证金20万元出借给朋友甲旅游，借出2个月后郑某将钱如数归还。出借过程中，郑某未谋取个人利益。郑某出借保证金的事被媒体曝光后，造成恶劣的社会影响。郑某的行为构成（ ）",
        "answer": "B"
    },
    {
        "id": 850,
        "title": "夏某是我国新华社高级编辑，香港某报记者董某请求夏某为其搞一份机密文件。夏某到社长办公室时发现该文件就放在桌上，趁社长不注意将文件拿出复制一份，然后通过电子邮件发给董某。董某给夏某感谢费5万港币。夏某的行为构成（ ）",
        "answer": "B"
    },
    {
        "id": 851,
        "title": "下列关于涉案财物的处理，做法错误的是（ ）",
        "answer": "D"
    },
    {
        "id": 852,
        "title": "甲县徐某因父亲与邻居史某发生纠纷将史某打成重伤，被公安机关批准刑事拘留并网上追逃。1月1日8时，徐某在千里之外的乙县被抓获。1月3日9时，甲县公安机关到达乙县押解徐某。1月5日10时，徐某被押解回甲县，送看守所羁押。下列说法正确的是（ ）",
        "answer": "B"
    },
    {
        "id": 853,
        "title": "苏某涉嫌参加恐怖组织罪被执行逮捕。苏某同事翁某为知情人员，公安机关尚未对翁某取证。同案犯柳某在逃。下列关于同志苏某家属的说法正确的是（ ）",
        "answer": "C"
    },
    {
        "id": 854,
        "title": "下列关于突发事件处置应对工作，做法错误的是（ ）",
        "answer": "C"
    },
    {
        "id": 855,
        "title": "因争夺水源，A省B市C县和D县交界处村民发生大规模械斗，地点主要发生在D县境内。该突发事件应当由（ ）负责。",
        "answer": "B"
    },
    {
        "id": 856,
        "title": "下列案（事）件处置，做法错误的是（ ）",
        "answer": "D"
    },
    {
        "id": 857,
        "title": "中华人民共和国全国人民代表大会是（ ）",
        "answer": "B"
    },
    {
        "id": 858,
        "title": "中华人民共和国主席、副主席由（ ）选举。",
        "answer": "C"
    },
    {
        "id": 859,
        "title": "下列可以使用继续盘问的情形是（ ）",
        "answer": "C"
    },
    {
        "id": 860,
        "title": "下列属于约束性警械的是（ ）",
        "answer": "D"
    },
    {
        "id": 861,
        "title": "某市民众受不法分子谣言蛊惑，误以为该市要建造高度污染的化工项目，遂于周末在市政府门前非法举行游行、示威，造成当地交通瘫痪。为维护正常社会秩序，现场执勤民警经警告无效，（ ）",
        "answer": "C"
    },
    {
        "id": 862,
        "title": "下列属于《治安管理处罚法》规定的处罚种类是（ ）",
        "answer": "D"
    },
    {
        "id": 863,
        "title": "某公安派出所接到某公司报案称收到一封恐吓信，信中称有人在其公司安装了炸弹。公安机关迅速出警，未发现有任何炸弹。后查明是公司员工李某为报复领导而搞的恶作剧。因周末休息，此案未造成人员恐慌，未有任何财产损失。对李某应当（ ）",
        "answer": "D"
    },
    {
        "id": 864,
        "title": "根据《治安管理处罚法》的规定，旅馆工作人员在公安机关查处（ ）活动时，为违法犯罪行为人通风报信的，对其予以治安管理处罚。",
        "answer": "A"
    },
    {
        "id": 865,
        "title": "公安机关在办理赵某故意损毁财物治安案件时，调查了与赵某有关的下列事实，其中不需要调查的是（ ）",
        "answer": "D"
    },
    {
        "id": 866,
        "title": "根据《治安管理处罚法》的规定，应从重给予治安管理处罚的是（ ）",
        "answer": "B"
    },
    {
        "id": 867,
        "title": "下列违反治安管理的行为，不适用治安调解的是（ ）",
        "answer": "C"
    },
    {
        "id": 868,
        "title": "反腐倡廉教育的重点对象是（     ）",
        "answer": "B"
    },
    {
        "id": 869,
        "title": "反腐倡廉教育的根本是（     ） ",
        "answer": "C"
    },
    {
        "id": 870,
        "title": "反腐倡廉教育要面向（     ）",
        "answer": "A"
    },
    {
        "id": 871,
        "title": "中央纪委、中央组织部和中央宣传部要切实抓好对（     ）领导干部的反腐倡廉教育工作。 ",
        "answer": "A"
    },
    {
        "id": 872,
        "title": "反腐倡廉教育要面向全社会，把思想道德教育、纪律教育与（     ）和法制教育结合起来。 ",
        "answer": "C"
    },
    {
        "id": 873,
        "title": "发展社会主义先进文化的重要内容和中心环节是（     ）",
        "answer": "B"
    },
    {
        "id": 874,
        "title": "反腐倡廉制度建设包括建立健全反腐倡廉基本制度以及推进从源头上防治腐败的制度（     ）两个大的方面。 ",
        "answer": "C"
    },
    {
        "id": 875,
        "title": "深化干部人事制度改革，要积极推进干部人事工作的科学化、民主化、制度化进程，扩大党员和群众对干部选拔任用的（     ）",
        "answer": "C"
    },
    {
        "id": 876,
        "title": "民主集中制是民主基础上的集中和集中指导下的民主相结合。它既是党的（     ），也是群众路线在党的生活中的运用。 ",
        "answer": "A"
    },
    {
        "id": 877,
        "title": "推进党务公开，增强党组织工作透明度的目的是使党员（     ）党内事务。 ",
        "answer": "C"
    },
    {
        "id": 878,
        "title": "《公安机关督察条例》于2011年8月24日国务院第169次常务会议修订通过，自2011年（     ）起实施。",
        "answer": "B"
    },
    {
        "id": 879,
        "title": "（     ）领导全国公安机关的督察工作。",
        "answer": "C"
    },
    {
        "id": 880,
        "title": "下列要求与警务督察工作指导方针不相符合的是（     ）",
        "answer": "D"
    },
    {
        "id": 881,
        "title": "下列关于停止执行职务和禁闭措施的说法，正确的是(     )",
        "answer": "D"
    },
    {
        "id": 882,
        "title": "停止执行职务的期限为(     )",
        "answer": "A"
    },
    {
        "id": 883,
        "title": "督察机构认为公安机关的人民警察违反纪律需要采取停止执行职务、禁闭措施的，由督察机构作出决定，报(     )批准后执行。",
        "answer": "A"
    },
    {
        "id": 884,
        "title": "受理申诉的公安机关对不服停止执行职务的申诉，应当自收到申诉之日起(      )内作出是否撤销停止执行职务的决定。",
        "answer": "C"
    },
    {
        "id": 885,
        "title": "督察标志和督察证件的式样由(     )制定。",
        "answer": "A"
    },
    {
        "id": 886,
        "title": "下列选项中不属于县级以上地方各级人民政府公安机关督察机构的职责的是（     ）",
        "answer": "C"
    },
    {
        "id": 887,
        "title": "下列选项中关于派出督察职责权限的说法不正确的是(     )",
        "answer": "C"
    },
    {
        "id": 888,
        "title": "公安机关办案单位对涉案人员采取继续盘问、刑事传唤、拘传、刑事拘留、监视居住等措施的，应将相关情况报(     )备案。",
        "answer": "D"
    },
    {
        "id": 889,
        "title": "公安机关及其人民警察对督察机构作出的决定不服的，提出复核申请和督察机构作出复核决定的期限分别是(     )",
        "answer": "D"
    },
    {
        "id": 890,
        "title": "对违反纪律的人民警察需要实施停止执行职务或者禁闭措施的程序不包括(     )",
        "answer": "C"
    },
    {
        "id": 891,
        "title": "认真核查处置群众投诉是指，要进一步加强督察部门核查处置投诉工作，拓宽畅通投诉渠道，严格执行24小时值班备勤制度，加强（     ），对正在发生的民警违法违纪行为，快速出警，依法处置，及时反馈，做到件件有着落、事事有回音。对重大敏感、复杂疑难投诉事项，上级督察部门要加大直接核查督办力度。",
        "answer": "C"
    },
    {
        "id": 892,
        "title": "切实维护民警执法权益是指，要认真履行督察部门牵头维护民警执法权益职责，落实公安部关于各级公安机关建立维护公安民警执法权益工作委员会、日常职能由督察部门承担的要求，地市级以上公安机关督察部门要建立维护民警执法权益工作队，推动（    ）的深入实施，预防和处置妨害民警依法履行职务行为，维护法律尊严和执法权威。",
        "answer": "A"
    },
    {
        "id": 893,
        "title": "按照《四川省公安厅处理群众信访事项工作规定》，对上级转送和本级接收的群众诉求事项，要在收到诉求的（     ）内，作出移送其他有管辖权部门的告知、移送下级公安机关依法处理的告知或本级受理、复查、复核的意见。",
        "answer": "B"
    },
    {
        "id": 894,
        "title": "为了规范公安机关办理信访事项的书面答复，告知等工作，2014年公安部制定了（     ）种专用法律文书。",
        "answer": "B"
    },
    {
        "id": 895,
        "title": "利用职务上的便利,占用公物归个人使用,时间超过（     ）,情节较重的,给予警告或者严重警告处分；情节严重的,给予撤销党内职务处分。 ",
        "answer": "C"
    },
    {
        "id": 896,
        "title": "惩治和预防腐败体系建设要坚持惩防并举、重在建设，在坚决惩治腐败的同时，更加注重治本,更加注重预防，（     ），做到惩治和预防两手抓、两手都要硬。 ",
        "answer": "C"
    },
    {
        "id": 897,
        "title": "在其职责范围内，不履行或者不正确履行自己的职责，对造成的损失或者后果起决定性作用的党员或者党员领导干部，属于（   ）。",
        "answer": "A"
    },
    {
        "id": 898,
        "title": "反腐倡廉工作要面向(     )",
        "answer": "A"
    },
    {
        "id": 899,
        "title": "《党内监督条例》中规定，党内监督的专门机关是（     ）",
        "answer": "D"
    },
    {
        "id": 900,
        "title": "对于使用网络技术措施的，应当制作(  )或《呈请采取技术侦查措施报告书》，按程序报有权审批人审批后提请网安部门开展网络技术措施使用协作。",
        "answer": "A"
    },
    {
        "id": 901,
        "title": "涉网案件初查中可以使用网络信息查询监控措施，调用公开数据、身份数据、（  ）",
        "answer": "C"
    },
    {
        "id": 902,
        "title": "以下关键词属于黑客类涉网案件黑话的是（  ）",
        "answer": "A"
    },
    {
        "id": 903,
        "title": "使用网络技术措施必须为在职在编人民警察，并通过（  ）以上网安部门组织的培训考试。",
        "answer": "C"
    },
    {
        "id": 904,
        "title": "网络犯罪的特点以下描述错误的是（  ）",
        "answer": "C"
    },
    {
        "id": 905,
        "title": "电子数据提取时，下列做法错误的是（  ）",
        "answer": "D"
    },
    {
        "id": 906,
        "title": "网络远程勘验由办理案件的（  ）负责。",
        "answer": "B"
    },
    {
        "id": 907,
        "title": "关于网络犯罪的发展趋势具体体现，下列描述错误的是（  ）",
        "answer": "C"
    },
    {
        "id": 908,
        "title": "设置关键词监测，即在搜索引擎中设置关键词进行监测。关键词检索中，按照（  ）排序可更好突出重点内容。",
        "answer": "B"
    },
    {
        "id": 909,
        "title": "在实际工作中，经常出现网页被404的情况，以搜索引擎为工具，以下不能使用的技巧是（  ）",
        "answer": "A"
    },
    {
        "id": 910,
        "title": "微博主页左侧（  ）可以提供一定的个人身份信息。",
        "answer": "C"
    },
    {
        "id": 911,
        "title": "短视频、直播应用，除了常规的关键词搜索等方法外，可以（  ），以缩小获取信息的地域范围，提高发现需要关注信息的几率。",
        "answer": "C"
    },
    {
        "id": 912,
        "title": "网络舆情信息巡查在公开网络社区和半公开网络社区中进行。在工作中半公开网络社区涉及最多的为“两群”，即微信群和（  ）",
        "answer": "D"
    },
    {
        "id": 913,
        "title": "留存取证网页信息时，直接将页面整体另存为（  ）格式，或利用软件拷屏，包括图片、文字信息、发帖账号、时间、阅读量、点击量、跟帖量等。",
        "answer": "A"
    },
    {
        "id": 914,
        "title": "目前微博多指《新浪微博》，除在微博内使用关键词检索外，还应主动关注（  ），进行合理分组。",
        "answer": "C"
    },
    {
        "id": 915,
        "title": "部分公开网络社区（如今日头条）采用推送算法，可以通过长期使用来提高推送关注信息的（  ）",
        "answer": "D"
    },
    {
        "id": 916,
        "title": "新型网络赌博的主要表现形式是：1、（  ），2、以移动互联网游戏平台为犯罪载体。",
        "answer": "A"
    },
    {
        "id": 917,
        "title": "坚持科技支撑。深化基层信息化建设应用,依托大数据、人工智能等新技术,不断创新基层基础工作模式,丰富基层基础工作手段,实现（  ）、敏锐感知、动态管控、精准治理,提升基层打防管控的智能化水平。",
        "answer": "A"
    },
    {
        "id": 918,
        "title": "安全出口的疏散门应（  ）",
        "answer": "B"
    },
    {
        "id": 919,
        "title": "机关、团体、企业、事业等单位对建筑消防设施（  ）至少进行一次全面检查。",
        "answer": "C"
    },
    {
        "id": 920,
        "title": "安全防范，是指公安机关为了维护辖区的社会治安秩序，运用（  ），对各种社会治安问题和违法犯罪行为依法进行预防和控制的手段。",
        "answer": "D"
    },
    {
        "id": 921,
        "title": "公安派出所应当（  ）辖区居民和单位对本社区和单位内部进行巡逻、看护，并就巡逻、看护的工作方法进行指导。",
        "answer": "D"
    },
    {
        "id": 922,
        "title": "学校周界应设置实体屏障，围墙、围栏不低于（  ）米。",
        "answer": "C"
    },
    {
        "id": 923,
        "title": "学生在400人以上的学校，每增加（  ）名学生应增加一名保安人员。",
        "answer": "B"
    },
    {
        "id": 924,
        "title": "公安机关应在校门外学生安全区域即校门（  ）米范围内设置视频图像采集装置，确保能清晰显示监视区域内人员活动和治安秩序情况。",
        "answer": "C"
    },
    {
        "id": 925,
        "title": "“一区（村）一警”制度是指（  ）",
        "answer": "C"
    },
    {
        "id": 926,
        "title": "各分（市）县局不断完善“1+3+N”专群联动模式，建立了以群众组织为主的新型特色群防队伍，以下属于特色群防队伍的是（  ）",
        "answer": "D"
    },
    {
        "id": 927,
        "title": "在开展单元行动过程中，社区民警拓宽宣传渠道，采取多形式的宣传方式，深入基层为人民群众普及防火、防盗、禁毒、禁赌等法律法规，以下不属于宣传方式的是（  ）",
        "answer": "B"
    },
    {
        "id": 928,
        "title": "临时居民身份证的有效期限为（  ）,有效期限自签发之日起计算。",
        "answer": "B"
    },
    {
        "id": 929,
        "title": "未满十六周岁的公民，自愿申请领取居民身份证的，发给有效期（  ）的居民身份证。",
        "answer": "A"
    },
    {
        "id": 930,
        "title": "按照《国家发改革委、财政部、公安部关于居民身份证收费标准及有关问题的通知》规定，对城市中领取最低生活保障金的居民、农村中有特殊生活困难的居民，在其初次申请领取和换领居民身份证时（  ）",
        "answer": "C"
    },
    {
        "id": 931,
        "title": "公民身份号码由（  ）位数字组成。",
        "answer": "C"
    },
    {
        "id": 932,
        "title": "《居民身份证法》第二条规定： 居住在中华人民共和国境内的年满（  ）的中国公民，应当依照本法的规定申请领取居民身份证。",
        "answer": "B"
    },
    {
        "id": 933,
        "title": "港澳台居民前往内地（大陆）居住（  ），符合有合法稳定就业、合法稳定住所、连续就读条件之一的，根据本人意愿，可以申请领取居住证。",
        "answer": "B"
    },
    {
        "id": 934,
        "title": "环形路，以（  ）为方向编号。",
        "answer": "C"
    },
    {
        "id": 935,
        "title": "群众（常规类）通过流动人口自主申报平台申报的信息，辖区派出所应该在多少个工作日内完成信息的审核录入。",
        "answer": "D"
    },
    {
        "id": 936,
        "title": "按照省厅《2020年度治安系统考核评价指标及说明》，2020年度一标三实工作，新增维护适龄段（18-65周岁）人口重点要素信息考核指标，采集及准确率应达到（  ）",
        "answer": "C"
    },
    {
        "id": 937,
        "title": "和现实社会一样，（   ）也是网络社会制度的首要价值。",
        "answer": "A"
    },
    {
        "id": 938,
        "title": "（   ）不仅使得信息交流更加广泛、快捷而且也导致有害信息的泛滥，引发网络社会的道德危机问题。",
        "answer": "B"
    },
    {
        "id": 939,
        "title": "网络舆论发展受多方面因素影响，主要包括政府部门的参与、传统媒体的加入、（   ）",
        "answer": "C"
    },
    {
        "id": 940,
        "title": "以下属于增强网络舆论引导有效方法是（   ）",
        "answer": "C"
    },
    {
        "id": 941,
        "title": "我国网络舆论的特点（   ）",
        "answer": "D"
    },
    {
        "id": 942,
        "title": "处理媒体引发的突发事件，其方法不包括（   ）",
        "answer": "C"
    },
    {
        "id": 943,
        "title": "在互联网环境中，网络舆论的形成变得较为复杂，但大致遵循（   ）模式。",
        "answer": "C"
    },
    {
        "id": 944,
        "title": "网络舆论的个性主要是丰富性、（   ）、多元性、冲突性和难控性。",
        "answer": "B"
    },
    {
        "id": 945,
        "title": "新修订的《中华人民共和国保守国家秘密法》自（   ）起施行。",
        "answer": "A"
    },
    {
        "id": 946,
        "title": "公安保密技防系统配备的公安网优盘颜色为（   ）",
        "answer": "C"
    },
    {
        "id": 947,
        "title": "公安工作国家秘密目录中绝密级（   ）条 。",
        "answer": "A"
    },
    {
        "id": 948,
        "title": "密码电报分为（   ）",
        "answer": "C"
    },
    {
        "id": 949,
        "title": "公安网是非涉密网，参照涉密网保密管理要求，必须设置（   ）位以上含数字、字母、标点符号的开机密码。",
        "answer": "A"
    },
    {
        "id": 950,
        "title": "各级公安机关、单位主要负责人为本机关、单位（   ）定密责任人，对定密工作负总责。",
        "answer": "B"
    },
    {
        "id": 951,
        "title": "市（州）公安机关具有（   ）国家秘密的原始定密权。",
        "answer": "B"
    },
    {
        "id": 952,
        "title": "公安机关涉密人员离岗离职的脱密期管理，一般情况下，重要涉密人员为（   ）",
        "answer": "A"
    },
    {
        "id": 953,
        "title": "携带绝密级纸介质载体应当经本机关、单位主管领导批准，并有（   ）以上同行。",
        "answer": "B"
    },
    {
        "id": 954,
        "title": "2015年7月1日，全国人大常委会通过的《国家安全法》规定，加强国家安全新闻宣传和舆论引导，确定每年（   ）为全民国家安全教育日。",
        "answer": "B"
    },
    {
        "id": 955,
        "title": "涉密人员因私出入境证件，应在回国（境）（   ）天内交由组织人事部门集中保管。",
        "answer": "C"
    },
    {
        "id": 956,
        "title": "市（州）公安机关及其授权的机关、单位可以确定（   ）国家秘密。",
        "answer": "D"
    },
    {
        "id": 957,
        "title": "公安机关机要岗位的民警离岗离职的脱密期为（   ）",
        "answer": "A"
    },
    {
        "id": 958,
        "title": "涉密人员因公出入境证件，应在回国（境）（   ）天内交由组织人事部门集中保管。",
        "answer": "B"
    },
    {
        "id": 959,
        "title": "根据国际生物识别小组统计结果，市场上有多种针对不同生理特征和行为特征的应用，其中占有率最高的是( )识别。",
        "answer": "C"
    },
    {
        "id": 960,
        "title": "完善社会治安防控体系的重要发力方向是（ ）",
        "answer": "B"
    },
    {
        "id": 961,
        "title": "（ ）是基层民警开展基础信息采录、业务办理、信息综合应用的“一站式”工作平台。",
        "answer": "A"
    },
    {
        "id": 962,
        "title": "（ ）是目前公安部唯一的面向全警提供综合信息检索服务的应用系统。",
        "answer": "A"
    },
    {
        "id": 963,
        "title": "公安部“互联网+公安政务服务”平台于（ ）上线运行。",
        "answer": "C"
    },
    {
        "id": 964,
        "title": "云计算是（ ）计算的一种。",
        "answer": "A"
    },
    {
        "id": 965,
        "title": "（ ）天气对无线系统通信影响最小。",
        "answer": "C"
    },
    {
        "id": 966,
        "title": "公安信息网用户不得擅自（ ）公安信息网及应用系统。",
        "answer": "D"
    },
    {
        "id": 967,
        "title": "经公安部批准，按照国家保密规定和标准，通过符合标准规范的（ ）实现公安信息网与互联网或者其它网络信息的安全交换。",
        "answer": "A"
    },
    {
        "id": 968,
        "title": "公安民警访问存储公民个人信息的系统，应当实行（ ）、访问控制、安全审计，并留存操作日志。",
        "answer": "D"
    },
    {
        "id": 969,
        "title": "根据《公安部关于印发<关于进一步加强公安机关视频图像信息应用工作的意见>的通知》（公通字【2015】4号）的相关规定，按照安装部位的重要程度，视频监控点位分为（ ）类。",
        "answer": "B"
    },
    {
        "id": 970,
        "title": "近年来，视频监控系统成为公安机关（ ）的重要增长点。",
        "answer": "A"
    },
    {
        "id": 971,
        "title": "视频图像信息应用方法是在实际工作中，侦查员为迅速、有效侦破案(事)件，根据侦查工作的实际情况，以（ ）为核心，在视频侦查技术范畴内，与各种不同的具体技术手段相结合，衍生出的一系列视频图像信息应用方法。",
        "answer": "D"
    },
    {
        "id": 972,
        "title": "健康是人们在生理、心理和（  ）三个方面所获得的一种稳定和谐和完善的状态。",
        "answer": "D"
    },
    {
        "id": 973,
        "title": "下列有关心理健康工作的认识正确的选项是（  ）",
        "answer": "C"
    },
    {
        "id": 974,
        "title": "情绪是客观事物与自己的（  ）是否相符产生的态度体验。",
        "answer": "C"
    },
    {
        "id": 975,
        "title": "情绪控制“stop”技术有（  ）步骤。",
        "answer": "D"
    },
    {
        "id": 976,
        "title": "改变不为社会所接受的动机、欲望，使之符合社会规范和时代要求的情绪调整方法是（  ）",
        "answer": "B"
    },
    {
        "id": 977,
        "title": "心理压力是指（  ）",
        "answer": "A"
    },
    {
        "id": 978,
        "title": "（  ）表现不属于躯体化症状。",
        "answer": "D"
    },
    {
        "id": 979,
        "title": "（  ）表现属于抑郁症状。",
        "answer": "C"
    },
    {
        "id": 980,
        "title": "（  ）不属于应对心理问题的正确方式。",
        "answer": "C"
    },
    {
        "id": 981,
        "title": "要做到规律睡眠，一周（  ）天尽量做到规律起床和就寝，建立起睡眠“生物钟”。",
        "answer": "D"
    },
    {
        "id": 982,
        "title": "避免睡前过度刺激，以下说法错误的是（  ）",
        "answer": "B"
    },
    {
        "id": 983,
        "title": "下列正确地体现人际交往基本方法和技巧的是（  ）",
        "answer": "D"
    },
    {
        "id": 984,
        "title": "下列选项中，正确运用了人际交往的基本方法和技巧的是（  ）",
        "answer": "C"
    },
    {
        "id": 985,
        "title": "积极心态是（  ）形成的。",
        "answer": "C"
    },
    {
        "id": 986,
        "title": "在盘查车辆时容易被车内驾驶员用匕首伤害的区域是（  ）",
        "answer": "A"
    },
    {
        "id": 987,
        "title": "对嫌疑车辆盘查过程中，下列表述不合理的是（  ）",
        "answer": "B"
    },
    {
        "id": 988,
        "title": "对嫌疑车辆盘查过程中，下列表述合理的是（  ）",
        "answer": "C"
    },
    {
        "id": 989,
        "title": "下列不属于对普通嫌疑车辆盘查的原则的是（  ）",
        "answer": "A"
    },
    {
        "id": 990,
        "title": "下列不属于对嫌疑车辆盘查的总体要求的是（  ）",
        "answer": "D"
    },
    {
        "id": 991,
        "title": "符合安全用枪规范的是 （  ）",
        "answer": "A"
    },
    {
        "id": 992,
        "title": "交接枪支过程中不正确的操作（  ）",
        "answer": "C"
    },
    {
        "id": 993,
        "title": "下列不属于半自动手枪安全检查步骤的是（  ）",
        "answer": "D"
    },
    {
        "id": 994,
        "title": "以下哪种情况不适用武器 (  )",
        "answer": "C"
    },
    {
        "id": 995,
        "title": "枪支入套时哪个动作是错误的 (  )",
        "answer": "B"
    },
    {
        "id": 996,
        "title": "盾牌戒备式中，盾牌上缘应高于（  ）",
        "answer": "D"
    },
    {
        "id": 997,
        "title": "公安机关的人民警察对有违法犯罪嫌疑的人员，经出示证件后，可以当场盘问检查是根据（  ）",
        "answer": "A"
    },
    {
        "id": 998,
        "title": "盾牌戒备式中，持盾双手大小臂间的夹角应为（  ）",
        "answer": "C"
    },
    {
        "id": 999,
        "title": "长棍腰间戒备式中，当前手为左手时，前脚应为(  )",
        "answer": "A"
    },
    {
        "id": 1000,
        "title": "盾牌撞击技术，以下说法最准确的是（  ）",
        "answer": "A"
    },
    {
        "id": 1001,
        "title": "前臂挡隔技术要求大臂与小臂的夹角（  ）",
        "answer": "D"
    },
    {
        "id": 1002,
        "title": "向后倒地保护技术保护的重点是（  ）",
        "answer": "C"
    },
    {
        "id": 1003,
        "title": "使用“安全带”摔时是从（  ）接近嫌疑人。",
        "answer": "B"
    },
    {
        "id": 1004,
        "title": "倒地后的地面戒备动作中，抬起的那只脚高度大约在（  ）",
        "answer": "D"
    },
    {
        "id": 1005,
        "title": "非特殊必要情况下警棍击打部位是（  ）",
        "answer": "C"
    },
    {
        "id": 1006,
        "title": "1.标志着中国新民主主义革命开端的历史事件是()。",
        "answer": "B"
    },
    {
        "id": 1007,
        "title": "第一次明确提出彻底的反帝反封建民主革命纲领的会议是（）",
        "answer": "B"
    },
    {
        "id": 1008,
        "title": "1935年12月，中共中央在( )上确定了抗日民族统一战线的策略方针",
        "answer": "C"
    },
    {
        "id": 1009,
        "title": "1945年( )，日本代表在投降书上签字，日军128万人向中国投降。至此，中国抗日战争胜利结束，世界反法西斯战争也胜利结束。",
        "answer": "A"
    },
    {
        "id": 1010,
        "title": "国民党发动全面内战是从1946年进攻( )解放区开始的。",
        "answer": "D"
    },
    {
        "id": 1011,
        "title": "1947年7月至9月，在( )主持下，中共中央工作委员会在河北平山县西柏坡召开全国土地会议，制定了《中国土地法大纲》。",
        "answer": "C"
    },
    {
        "id": 1012,
        "title": "1950年6月朝鲜战争爆发，10月19日以(  )为司令员兼政治委员的中国人民志愿军奉命开赴朝鲜战场。",
        "answer": "B"
    },
    {
        "id": 1013,
        "title": "1954年9月15日至28日，第一届全国人民代表大会第一次会议在北京隆重召开。大会通过了( )，以根本大法的形式，把中国共产党在过渡时期的总路线作为国家在过渡时期的总任务确定下来。",
        "answer": "A"
    },
    {
        "id": 1014,
        "title": "( )年，我国成功地爆炸第一颗原子弹，有力地打破了超级大国的核垄断和核讹诈。",
        "answer": "B"
    },
    {
        "id": 1015,
        "title": "1971年10月25日，第( )届联合国大会通过决议，恢复中华人民共和国在联合国的一切合法权益。",
        "answer": "B"
    },
    {
        "id": 1016,
        "title": "把发展问题同党的性质、党的执政理念联系起来，明确提出发展是我们党执政兴国的第一要务的是( )",
        "answer": "C"
    },
    {
        "id": 1017,
        "title": "1936年10月，红军三大主力在甘肃会宁胜利会师，标志着震撼世界的（ ）取得了完全的胜利。",
        "answer": "D"
    },
    {
        "id": 1018,
        "title": "延安整风运动的基本方针是（ ）",
        "answer": "D"
    },
    {
        "id": 1019,
        "title": "1945年4月23日至6月11日，党的七大在延安举行。这次会议将（ ）作为党的指导思想写入党章。",
        "answer": "B"
    },
    {
        "id": 1020,
        "title": "在俄国十月革命影响下，率先在中国举起马克思主义旗帜的是（ ）",
        "answer": "A"
    },
    {
        "id": 1021,
        "title": "1930年1月，毛泽东阐述中国革命“以乡村为中心”思想的著作是（ ）。",
        "answer": "C"
    },
    {
        "id": 1022,
        "title": "中国共产党制定《抗日救国十大纲领》的会议是（ ）",
        "answer": "B"
    },
    {
        "id": 1023,
        "title": "1938年，毛泽东发表的系统阐述抗日战争特点、前途和发展规律的著作是（ ）",
        "answer": "B"
    },
    {
        "id": 1024,
        "title": "1949年6月，毛泽东发表的系统论述中国共产党建国主张的著作是（ ）",
        "answer": "D"
    },
    {
        "id": 1025,
        "title": "我们要构建的社会主义和谐社会的总要求是：民主法治、_______、诚信友爱、充满活力、安定有序、人与自然和谐相处。",
        "answer": "D"
    },
    {
        "id": 1026,
        "title": "厅党委对涉藏工作“四底”要求是（    ）",
        "answer": "A"
    },
    {
        "id": 1027,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）以后，由于西方列强入侵和封建统治腐败，中国逐步成为半殖民地半封建社会，国家蒙辱、人民蒙难、文明蒙尘，中华民族遭受了前所未有的劫难。",
        "answer": "A"
    },
    {
        "id": 1028,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）推翻了统治中国几千年的君主专制制度，但未能改变中国半殖民地半封建的社会性质和中国人民的悲惨命运。",
        "answer": "A"
    },
    {
        "id": 1029,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，十月革命一声炮响，给中国送来了马克思列宁主义。（ ）促进了马克思主义在中国的传播。",
        "answer": "D"
    },
    {
        "id": 1030,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，一九二七年国民党内反动集团叛变革命，残酷屠杀共产党人和革命人民，由于党内以（ ）为代表的右倾思想发展为右倾机会主义错误并在党的领导机关中占了统治地位，党和人民不能组织有效抵抗，致使大革命在强大的敌人突然袭击下遭到惨重失败。",
        "answer": "A"
    },
    {
        "id": 1031,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）打响武装反抗国民党反动派的第一枪，标志着中国共产党独立领导革命战争、创建人民军队和武装夺取政权的开端。",
        "answer": "C"
    },
    {
        "id": 1032,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）确定实行土地革命和武装起义的方针。",
        "answer": "D"
    },
    {
        "id": 1033,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）是中国革命具有决定意义的新起点。",
        "answer": "B"
    },
    {
        "id": 1034,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，毛泽东同志领导军民在（ ）建立第一个农村革命根据地，党领导人民打土豪、分田地。",
        "answer": "A"
    },
    {
        "id": 1035,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）确立思想建党、政治建军原则。",
        "answer": "D"
    },
    {
        "id": 1036,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，由于王明“左”倾教条主义在党内的错误领导，中央革命根据地（ ）失败，红军不得不进行战略转移，经过艰苦卓绝的长征转战到陕北。",
        "answer": "A"
    },
    {
        "id": 1037,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，抗日战争时期，（ ）后，中日民族矛盾逐渐超越国内阶级矛盾上升为主要矛盾。",
        "answer": "A"
    },
    {
        "id": 1038,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，在日本帝国主义加紧侵略我国、民族危机空前严重的关头，党率先高举武装抗日旗帜，广泛开展抗日救亡运动，促成（ ）和平解决，对推动国共再次合作、团结抗日起了重大历史作用。",
        "answer": "D"
    },
    {
        "id": 1039,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，解放战争时期，面对国民党反动派悍然发动的全面内战，党领导广大军民逐步由积极防御转向战略进攻，打赢（ ）三大战役和渡江战役，向中南、西北、西南胜利进军，消灭国民党反动派八百万军队，推翻国民党反动政府，推翻帝国主义、封建主义、官僚资本主义三座大山。",
        "answer": "D"
    },
    {
        "id": 1040,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，在革命斗争中，以毛泽东同志为主要代表的中国共产党人，把马克思列宁主义基本原理同中国具体实际相结合，对经过艰苦探索、付出巨大牺牲积累的一系列独创性经验作了理论概括，开辟了（ ）的正确革命道路，创立了毛泽东思想，为夺取新民主主义革命胜利指明了正确方向。",
        "answer": "C"
    },
    {
        "id": 1041,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，在革命斗争中，党弘扬（ ）的伟大建党精神。",
        "answer": "C"
    },
    {
        "id": 1042,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，党实施和推进党的建设伟大工程，提出着重从思想上建党的原则，坚持民主集中制，坚持理论联系实际、密切联系群众、批评和自我批评三大优良作风，形成（ ）三大法宝。",
        "answer": "A"
    },
    {
        "id": 1043,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，要努力建设（ ）马克思主义政党。",
        "answer": "A"
    },
    {
        "id": 1044,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）为建立新民主主义的新中国制定了正确路线方针政策，使全党在思想上政治上组织上达到空前统一和团结。",
        "answer": "D"
    },
    {
        "id": 1045,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，实践充分说明，历史和人民选择了中国共产党，没有中国共产党领导，（ ）是不可能实现的。",
        "answer": "C"
    },
    {
        "id": 1046,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，社会主义革命和建设时期，党面临的主要任务是，（ ）",
        "answer": "B"
    },
    {
        "id": 1047,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）党正式提出过渡时期的总路线，即在一个相当长的时期内，逐步实现国家的社会主义工业化，并逐步实现国家对农业、手工业和资本主义工商业的社会主义改造。",
        "answer": "D"
    },
    {
        "id": 1048,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，（ ）为我国一切进步和发展奠定了重要基础。",
        "answer": "D"
    },
    {
        "id": 1049,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，党的八大根据我国社会主义改造基本完成后的形势，提出国内主要矛盾已经不再是工人阶级和资产阶级的矛盾，而是（ ）。",
        "answer": "A"
    },
    {
        "id": 1050,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，党提出努力把我国逐步建设成为一个具有（ ）的社会主义强国，领导人民开展全面的大规模的社会主义建设。",
        "answer": "A"
    },
    {
        "id": 1051,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，党审时度势调整外交战略，推动恢复我国在联合国的一切合法权利，打开对外工作新局面，推动形成国际社会（ ）的格局。",
        "answer": "A"
    },
    {
        "id": 1052,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，党充分预见到在全国执政面临的新挑战，早在解放战争取得全国胜利前夕召开的党的（ ）就向全党提出，务必继续保持谦虚、谨慎、不骄、不躁的作风，务必继续保持艰苦奋斗的作风。",
        "answer": "A"
    },
    {
        "id": 1053,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，完成社会主义革命和推进社会主义建设时期，毛泽东同志提出把马克思列宁主义基本原理同中国具体实际进行“第二次结合”，走出一条适合我国国情的（ ）。",
        "answer": "A"
    },
    {
        "id": 1054,
        "title": "中共中央关于党的百年奋斗重大成就和历史经验的决议中提到，“文化大革命”结束以后，在党和国家面临何去何从的重大历史关头，党深刻认识到，只有（ ）才是唯一出路，否则我们的现代化事业和社会主义事业就会被葬送。",
        "answer": "C"
    }
]

const result = computed(() => {
  if (keywords.value) {
    const res = []
    console.log('111221'.indexOf('22'))
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      if (item.title.indexOf(keywords.value) > -1) {
        res.push(item)
      }
    }
    console.log(res)
    return res
  }
  else {
    return []
  }
})
</script>


<style>
input {
  width: 100%;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
</style>